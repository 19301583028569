module.exports = {
	"sidebar.section.main_menu": "Main Menu",
	"sidebar.dashboard": "Dashboard",
	"sidebar.report": "Report",
	"sidebar.sale": "Sale",
	"sidebar.sale_customer": "Customer Card",
	"sidebar.sale_qt": "Sale Quotation",
	"sidebar.purchase": "Purchase",
	"sidebar.purchase_supplier": "Supplier Card",
	"sidebar.purchase_po": "Purchase Order",
	"sidebar.hr": "HR",
	"sidebar.hr_staff": "Employee",
	"sidebar.hr_timesheet": "Timesheet",
	"sidebar.hr_payroll": "Payroll",
	"sidebar.hr_department": "Department",
	"sidebar.hr_division": "Division",
	"sidebar.pm": "PM",
	"sidebar.pm_project": "Project",
	"sidebar.pm_assignment": "Assignment",
	"sidebar.pm_do": "Delivery Order",
	"sidebar.stock": "Warehouse",
	"sidebar.stock_product": "Product Stock",
	"sidebar.stock_category": "Category",
	"sidebar.stock_sort": "Type",
	"sidebar.account": "Account",
	"sidebar.account_invoice": "Invoice",
	"sidebar.account_invoicebill": "Billing Note/Invoice",
	"sidebar.account_receipttaxinvoice": "Tax Invoince/Receipt",
	"sidebar.account_receipt": "Receipt",
	"sidebar.account_taxinvoice": "Tax Invoince",
	"sidebar.operation_pr": "Purchase Request",
	"sidebar.operation_eqr": "Equipment Request",
	"sidebar.operation_pdr": "Product Request",
	"sidebar.operation_ot": "OT Request",
	"sidebar.warehouse" : "คลังสินค้า",
	"sidebar.warehouse_PRD_stock" : "สินค้า",
	"sidebar.warehouse_PRD" : "รายการสินค้า",
	"sidebar.warehouse_PRD_category" : "หมวดหมู่สินค้า",
	"sidebar.warehouse_PRD_type" : "ประเภทสินค้า",
	"sidebar.warehouse_PRD_brand" : "แบรนด์สินค้า",
	"sidebar.warehouse_PRD_model" : "รุ่นสินค้า",
	"sidebar.services": "งานบริการ",
	"sidebar.services_air": "รายการแจ้งซ่อม",
	"sidebar.services_aircalendar": "นัดหมายซ่อม",

	"erp.doc_no" : "Document No",
	"erp.ref_doc_no" : "Ref. Doc No",
	"erp.project" : "Project",
	"erp.doc_date" : "Date",
	"erp.total_amount" : "Sub total",
	"erp.discount_amount" : "Discount",
	"erp.value_amount" : "Sub total less discount",
	"erp.vat_amount" : "Tax",
	"erp.net_amount" : "Total",
	"erp.net_amount2" : "Net Amount",
	"erp.item_id" : "Code",
	"erp.item_name" : "Description",
	"erp.item_num" : "Qty",
	"erp.item_unit_price" : "Unit Price",
	"erp.item_amount" : "Amount",
	"erp.payment_condition": "Payment Condition",
	"erp.docviewtype" : "Document Type",
	"erp.docprinttype": "Original/Copy",
	"erp.docprinttype.original" : "Original",
	"erp.docprinttype.copy" : "Copy",

	"quotation.title" : "QUOTATION",
	"quotation.title2" : " ",
	"quotation.customer" : "Company",
	"quotation.sale_person" : "Sale Person",
	"quotation.payment_term" : "Payment Term",
	"quotation.value_amount" : "Sub total less discount",
	"quotation.proposed_by" : "Proposed by",
	"quotation.authorized_by" : "Approve by",

	"receipt.title" : "RECEIPT",
	"receipt.title2" : " ",
	"receipt.customer" : "Company",
	"receipt.value_amount" : "Sub total less discount",
	"receipt.received_by" : "Good received by",
	"receipt.financial_by" : "Financial by",
	"receipt.authorized_by" : "Authorized by",

	"receipttaxinvoice.title" : "TAX INVOICE / RECEIPT",
	"receipttaxinvoice.title2" : " ",

	"taxinvoice.title" : "TAX INVOICE",
	"taxinvoice.title2" : " ",

	"invoice.title" : "INVOICE",
	"invoice.title2" : " ",
	"invoice.customer" : "Company",
	"invoice.payment_term" : "Payment Credit",
	"invoice.po" : "Ref. PO",
	"invoice.value_amount" : "Sub total less discount",
	"invoice.authorized_by" : "Authorized by",
	"invoice.checked_by" : "Audit by",
	"invoice.received_by" : "Good received by",

	"invoicebill.title" : "BILLING NOTE / INVOICE",
	"invoicebill.title2" : " ",

	"staff.profile" : "Profile",
	"staff.profile_image" : "Photo",
	"staff.card" : "Citizen Card",
	"staff.code" : "Code",
	"staff.name" : "Name",
	"staff.nickname" : "Nickname",
	"staff.position" : "Position",
	"staff.department" : "Department",
	"staff.division" : "Division",
	"staff.phone": "Phone No",
	"staff.email": "Email",
	"staff.birthday": "Birthday",
	"staff.gender": "Gender",
	"staff.card_id": "Citizen ID",
	"staff.role" : "User Role",
	"staff.registered_date": "Started Date",
	"staff.resigned_date": "Resigned Date",
	"staff.staff_status": "Employment",
	"staff.staff_type": "Employee Type",
	"staff.user_status": "User Status",
	"staff.password": "Password for this user",
	"staff.benefit": "Benefit & Bonus",
	"staff.salary": "Salaray",
	"staff.timework": "Working Time",
	"staff.timein": "In time",
	"staff.timeout": "Out time",

	"timesheet.title" : "Timesheet",
	"timesheet.title2" : "Document",
	"timesheet.in" : "In time",
	"timesheet.out" : "Out time",
	"timesheet.volume" : "Total working staff",

	"payroll.title" : "Payslip Summary",
	"payroll.title2" : "Document",
	"payroll.doc_date" : "Date as of",
	"payroll.volume" : "Total Payment",
	"payroll.item_salary": "Salary",
	"payroll.item_day": "Days",
	"payroll.item_revenue": "Revenue",
	"payroll.item_ot": "OT",
	"payroll.item_net_revenue": "Net Revenue",
	"payroll.item_ddvat": "- Tax",
	"payroll.item_ddins": "- Insurance",
	"payroll.item_ddleave": "- Leave",
	"payroll.item_ddcard": "- Card",
	"payroll.item_ddother": "- Other",
	"payroll.item_payment": "Net Payment",

	"project.customer" : "Customer",
	"project.doc_date" : "Started Date",
	"project.doc_no" : "Project No",
	"project.project" : "Project Name",
	"project.ref_doc_no" : "Contract No",
	"project.pm" : "PM",
	"project.net_amount" : "มูลค่าโครงการ",
	"project.description" : "รายละเอียดโครงการ",
	"project.payment" : "การชำระเงิน",
	"project.project_type" : "Project Type",
	"project.finished_date" : "Finished Date",

	"assignment.title" : "Assignment",
	"assignment.title2" : "Document",
	"assignment.doc_date" : "Started Date",
	"assignment.doc_no" : "Job No",
	"assignment.ref_doc_no" : "Job Name",
	"assignment.owner_id" : "Project Manager",
	"assignment.finished_date" : "Finished Date",
	"assignment.volume" : "Number of tasks",
	"assignment.item_no": "No.",
	"assignment.item_topic": "Topic",
	"assignment.item_person": "Person in charge",
	"assignment.item_assign_date": "Assign date",
	"assignment.item_due_date": "Due date",
	"assignment.item_result": "Result",
	"assignment.item_due": "Due",

	"department.code" : "รหัสแผนก",
	"department.text" : "ชื่อแผนก",

	"PRD.code": "รหัสสินค้า",
	"PRD.name": "ชื่อสินค้า",
	"PRD.information": "ข้อมูลสินค้า",
	"PRD.photo_main": "รูปภาพสินค้า",
	"PRD.type": "ประเภทสินค้า",
	"PRD.category": "หมวดหมู่สินค้า",
	"PRD.brand": "แบรนด์",
	"PRD.model": "รุ่น",
	"PRD.size": "ขนาด",
	"PRD.color": "สี",
	"PRD.unit": "หน่วย",
	"PRD.value": "ราคาขาย",

	"PRDentry.code": "รหัสอ้างอิง",
	"PRDentry.code_hint": "Unique ID / IMEI / Serial No",
	"PRDentry.name": "สินค้า",
	"PRDentry.type": "ประเภทสินค้า",
	"PRDentry.brand": "แบรนด์",
	"PRDentry.model": "รุ่น",
	"PRDentry.location": "ที่เก็บปัจจุบัน",
	"PRDentry.description": "รายละเอียด",
	"PRDentry.qty": "จำนวน",
	"PRDentry.created_time": "นำเข้าสต็อก",
	"PRDentry.status_time": "สถานะล่าสุด",
	"PRDentry.status.Ready": "พร้อมขาย",
	"PRDentry.status.Checking": "ตรวจสอบ",
	"PRDentry.status.Spare": "สำรอง",
	"PRDentry.status.OutOfOrder": "เสีย",
	"PRDentry.status.Unknown": "ไม่ทราบสถานะ",
	"PRDentry.ref_entry": "รายการอ้างอิง",
	"PRDentry.child_codes": "SIM",

	"AST.code": "รหัสอุปกรณ์",
	"AST.name": "ชื่ออุปกรณ์",
	"AST.information": "ข้อมูลอุปกรณ์",
	"AST.photo_main": "รูปภาพอุปกรณ์",
	"AST.type": "ประเภทอุปกรณ์",
	"AST.category": "หมวดหมู่อุปกรณ์",
	"AST.brand": "แบรนด์",
	"AST.model": "รุ่น",
	"AST.size": "ขนาด",
	"AST.color": "สี",
	"AST.unit": "หน่วย",
	"AST.value": "มูลค่า",

	"ASTentry.code": "รหัสอ้างอิง",
	"ASTentry.code_hint": "Unique ID / IMEI / Serial No",
	"ASTentry.name": "อุปกรณ์",
	"ASTentry.type": "ประเภทอุปกรณ์",
	"ASTentry.brand": "แบรนด์",
	"ASTentry.model": "รุ่น",
	"ASTentry.location": "ที่เก็บ/ถือครอง",
	"ASTentry.description": "รายละเอียด",
	"ASTentry.qty": "จำนวน",
	"ASTentry.created_time": "นำเข้าสต็อก",
	"ASTentry.status_time": "สถานะล่าสุด",
	"ASTentry.status.Ready": "พร้อมใช้งาน",
	"ASTentry.status.Checking": "ตรวจสอบ",
	"ASTentry.status.InUsed": "เบิกใช้งาน",
	"ASTentry.status.OutOfOrder": "เสีย",
	"ASTentry.status.Unknown": "ไม่ทราบสถานะ",
	"ASTentry.ref_entry": "กล่องสินค้า",

	"SPY.code": "รหัสวัสดุ",
	"SPY.name": "ชื่อวัสดุ",
	"SPY.information": "ข้อมูลวัสดุ",
	"SPY.photo_main": "รูปภาพ",
	"SPY.type": "ประเภท",
	"SPY.category": "หมวดหมู่",
	"SPY.brand": "แบรนด์",
	"SPY.model": "รุ่น",
	"SPY.size": "ขนาด",
	"SPY.color": "สี",
	"SPY.unit": "หน่วย",
	"SPY.value": "มูลค่า",

	"SPYentry.code": "รหัสอ้างอิง",
	"SPYentry.code_hint": "รหัส Lot No / กล่อง / ชุด",
	"SPYentry.name": "วัสดุ",
	"SPYentry.type": "ประเภท",
	"SPYentry.brand": "แบรนด์",
	"SPYentry.model": "รุ่น",
	"SPYentry.location": "ที่เก็บ",
	"SPYentry.description": "รายละเอียด",
	"SPYentry.qty": "จำนวน",
	"SPYentry.created_time": "นำเข้าสต็อก",
	"SPYentry.status_time": "สถานะล่าสุด",
	"SPYentry.status.Ready": "พร้อมใช้งาน",
	"SPYentry.status.Checking": "ตรวจสอบ",
	"SPYentry.status.InUsed": "เบิกใช้งาน",
	"SPYentry.status.OutOfOrder": "เสีย",
	"SPYentry.status.Unknown": "ไม่ทราบสถานะ",
	"SPYentry.ref_entry": "รายการอ้างอิง",

	"PRD_category.code" : "รหัสหมวดหมู่สินค้า",
	"PRD_category.text" : "ชื่อหมวดหมู่สินค้า",
	"PRD_type.code" : "รหัสประเภทสินค้า",
	"PRD_type.text" : "ชื่อประเภทสินค้า",
	"PRD_type.ref_code" : "หมวดหมู่สินค้า",
	"PRD_brand.code" : "รหัสแบรนด์สินค้า",
	"PRD_brand.text" : "ชื่อแบรนด์สินค้า",
	"PRD_model.code" : "รหัสรุ่นสินค้า",
	"PRD_model.text" : "ชื่อรุ่นสินค้า",
	"PRD_model.ref_code" : "แบรนด์สินค้า",
	"AST_type.code" : "รหัสประเภทอุปกรณ์",
	"AST_type.text" : "ชื่อประเภทอุปกรณ์",
	"SPY_type.code" : "รหัสประเภทวัสดุ",
	"SPY_type.text" : "ชื่อประเภทวัสดุ",
	"location.code" : "รหัสคลังเก็บของ",
	"location.text" : "ชื่อคลังเก็บของ",
	"VHC_brand.code" : "รหัสแบรนด์",
	"VHC_brand.text" : "ชื่อแบรนด์",

	"airservice.title_for_technician" : "รายละเอียดการปฏิบัติงานช่าง",
	"airservice.title_photos" : "รูปภาพการปฏิบัติงาน",
	"airservice.detail": "ใบแจ้งซ่อม / ติดตั้งอุปกรณ์",
	"airservice.project" : "ประเภทงาน",
	"airservice.project_desc" : "ระบุประเภทงาน (อื่นๆ)",
	"airservice.price" : "ราคาที่เสนอ",
	"airservice.brcode" : "BR No.",
	"airservice.technician" : "เจ้าหน้าที่",
	"airservice.phone" : "เบอร์ติดต่อ",
	"airservice.bank" : "ธนาคาร",
	"airservice.branch" : "สาขา",
	"airservice.category" : "อาการเสีย",
	"airservice.doc_no" : "เลขงาน",
	"airservice.photo_request1" : "ภาพประกอบ 1",
	"airservice.photo_request2" : "ภาพประกอบ 2",
	"airservice.due_date" : "วันที่นัดหมาย",
	"airservice.service_description" : "รายละเอียดการซ่อม",
	"airservice.remark"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ Admin)",
	"airservice.remark_technician"  : "หมายเหตุ (สำหรับเจ้าหน้าที่ช่าง)",
	"airservice.status": "สถานะงาน",
	"airservice.ref_doc_no": "สถานะเอกสาร",
	"airservice.status.All": "ทั้งหมด",
	"airservice.status.Opened": "เปิดงาน",
	"airservice.status.OnWork": "รับงาน",
	"airservice.status.MakeAppointment": "นัดหมาย",
	"airservice.status.FollowAppointment": "เข้าตามนัดหมาย",
	"airservice.status.Closed": "ปิดงาน",
	"airservice.status.ClosedByTech": "ช่างปิดงาน",
	"airservice.status.Verified": "ตรวจสอบแล้ว",
	"airservice.status.Cancelled": "ยกเลิก",
	"airservice.created_time" : "่แจ้งงาน",
	"airservice.closed_time" : "ปิดงาน",
	"airservice.doc_date" : "วันที่แจ้งงาน",
	"airservice.created_user_name" : "ผู้แจ้ง",
	"airservice.item_no" : "ลำดับ",
	"airservice.item_type" : "ประเภท",
	"airservice.item_info" : "รายการ",
	"airservice.item_price" : "ราคา",
	"airservice.item_total_amount" : "รวม",

	"filter.start_date" : "จากวันที่",
	"filter.end_date" : "ถึงวันที่",

	"button.verified": "Approve",
	"status.Draft": "Draft",
	"status.Signed": "Approved",
	"status.Cancelled": "Cancelled",

	"address" : "Address",
	"phone" : "Phone",
	"fax" : "Fax",
	"taxno" : "Tax ID",
	"branch" : "Branch",
	"information" : "Info",
	"created_user_name" : "Create By",
	"created_date" : "Created Date",
	"last_updated_user_name" : "Last Updated By",
	"last_updated_date" : "Last Updated Date",
	"approved_user_name" : "Approved By",
	"approved_date" : "Approved Date"
}
