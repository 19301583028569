import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import {TableCell,TableSortLabel, Grid, CircularProgress} from '@material-ui/core';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';

import KTIcon from '../components/KTIcon'
import {MagbizGeneral} from '../../utils/Magbiz'
import settings from '../../settings'

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  headerCell: {
    flex: 1
  },
  tableCell: {
    flex: 1,
    backgroundColor: "#fff",
  },
  noClick: {
    cursor: 'initial',
  },
});


class MuiVirtualizedTable extends React.PureComponent {

  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick} = this.props;

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null, rowData, rowIndex}) => {
    const { columns, classes, rowHeight, disableRowClick, onRowColumnClick } = this.props;
    const style = { height: rowHeight , fontSize:'0.7rem', padding:2}
    if(typeof cellData === "string" && cellData.startsWith("[!") && cellData.endsWith("]")){
      style.color = "red"
    }
    let text = cellData
    if (columnIndex != null){
      if(columns[columnIndex].justifyContent){
        style.justifyContent = columns[columnIndex].justifyContent
        if (style.justifyContent === "center"){
          style.textAlign = "center"
        }else if (style.justifyContent === "flex-end"){
          style.textAlign = "right"
        }
      }
      if(columns[columnIndex].fontSize){
        style.fontSize = columns[columnIndex].fontSize
      }
      if(columns[columnIndex].color){
        style.color = columns[columnIndex].color
      }
      if(columns[columnIndex].onLeft){
        style.paddingLeft = 12
      }
      if(columns[columnIndex].onRight){
        style.paddingRight = 12
      }

      if (cellData && columns[columnIndex].maxLength && cellData.length > columns[columnIndex].maxLength){
        text = cellData.substr(0, columns[columnIndex].maxLength)+"..."
      }
    }
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, (disableRowClick ? classes.noClick : null), (columns[columnIndex].truncateLines ? "lineclamp"+columns[columnIndex].truncateLines : "") )}
        variant="body"
        style={style}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left' }
      >
        {(disableRowClick && columns[columnIndex].enableClick ?
          <div style={{cursor:"pointer", fontWeight:600}} onClick={() => onRowColumnClick({rowData:rowData, index:rowIndex, columnIndex:columnIndex})}>{text}</div> :
          text
        )}
      </TableCell>
    )
  };

  headerRenderer = ({ label, columnIndex, dataKey, enableSort, sortBy, sortDirection, onSortColumn }) => {
    const { headerHeight, columns, onSelectAll, classes } = this.props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };
    const sortKey = (columns[columnIndex].sortKey ? columns[columnIndex].sortKey : columns[columnIndex].dataKey)
    const allowSort = (enableSort && !columns[columnIndex].disableSort && (!columns[columnIndex].cellContentRenderer || columns[columnIndex].sortKey) ? true : false)

    let inner = null
    if(columnIndex === 0 && columns[columnIndex].dataKey === "selector"){
      inner = <div style={{cursor:"pointer"}} onClick={() => {
                  onSelectAll(columns[columnIndex].selected)
                }}>
                {columns[columnIndex].selected ? (<KTIcon vicon="check-box" vicontype="MaterialIcons" size={18} />):
                (<KTIcon vicon="check-box-outline-blank" vicontype="MaterialIcons" size={18} />)}
              </div>
    }else{
      inner = allowSort ? (
          <TableSortLabel active={sortKey === sortBy} direction={direction[sortDirection]}
            IconComponent={() => {
              let icon = "unfold-more-horizontal"
              if(sortKey === sortBy){
                if(sortDirection === SortDirection.ASC){
                  icon = "sort-ascending"
                }else{
                  icon = "sort-descending"
                }
              }
              return (
                <div style={{marginLeft:2}}><KTIcon vicon={icon} vicontype="MaterialCommunityIcons" size={14} color="grey" /></div>
              )
            }}
            onClick={() => {
              onSortColumn(sortKey, (sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC))
            }} >
            {label}
          </TableSortLabel>
        ) : (
          label
        )
    }

    const style = { height: headerHeight, fontWeight: 'bold', fontSize:'0.7rem', padding:2, lineHeight:1.1, color:settings.theme.secondary.contrastText}
    if (columnIndex != null){
      if(columns[columnIndex].justifyContent){
        style.justifyContent = columns[columnIndex].justifyContent
      }
      if(columns[columnIndex].onLeft){
        style.paddingLeft = 12
      }
    }

    return (
      <TableCell
        component="div"
        className={classNames(classes.headerCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={style}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { classes, columns, enableSort, sortBy, sortDirection, onSortColumn, ...tableProps } = this.props;
    return (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              className={classes.table}
              height={height}
              width={width}
              {...tableProps}
              rowClassName={this.getRowClassName}
            >
              {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = cellRendererProps =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }

                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      this.headerRenderer({
                        ...headerProps,
                        enableSort:enableSort,
                        sortBy: sortBy,
                        sortDirection: sortDirection,
                        onSortColumn: onSortColumn,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onRowColumnClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
  headerHeight: 44,
  rowHeight: 44,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

class TableList extends React.Component {

  createRows = (nrows) => {
    if(nrows){
      let newList = nrows
      if(this.props.columns && this.props.columns.length > 0 && this.props.columns[0].dataKey === "running"){
        newList = nrows.map((item, index) => {
          return {...item, running:index+1}
        })
      }
      return newList
    }
    return null
  }

  state = {
    sortBy: "",
    sortDirection: "",
    rows: this.createRows(this.props.rows)
  };

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.rows !== nextProps.rows){
      const newList = this.createRows(nextProps.rows)
      this.setState({ rows: newList })
      return false
    }
    if(this.props !== nextProps){return true}
    if(this.state !== nextState){return true}
    return false
  }

  render() {
    if(this.props.loading){
      return (
        <Fragment>
          <div style={{ height: this.props.height, width: this.props.width}}>
            <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'100%'}} >
              <CircularProgress />
            </Grid>
          </div>
        </Fragment>
      )
    }else if(!this.state.rows){
      return (
        <Fragment>
          <div style={{ height: this.props.height, width: this.props.width}}>
            <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'100%', color:"#BDBDBD"}} >
              Please Wait...
            </Grid>
          </div>
        </Fragment>
      )
    }else if(this.state.rows.length === 0){
      return (
        <Fragment>
          <div style={{ height: this.props.height, width: this.props.width}}>
            <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'100%', color:"#BDBDBD", fontSize:25}} >
              No data available !!!
            </Grid>
          </div>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <div style={{ height: this.props.height, width: this.props.width}}>
          <WrappedVirtualizedTable
            rowCount={this.state.rows.length}
            rowGetter={({ index }) => this.state.rows[index]}
            onRowClick={event => this.props.disableRowClick ? null : this.props.onRowClick(event)}
            onRowColumnClick={event => this.props.disableRowClick ? this.props.onRowClick(event) : null}
            columns={this.props.columns}
            rowHeight={this.props.rowHeight}
            enableSort={this.props.enableSort}
            disableRowClick={this.props.disableRowClick}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            onSortColumn={(sortBy, sortDirection) => {
              let newList = MagbizGeneral.sortByKey(this.props.rows, sortBy, sortDirection.toLowerCase())
              if(this.props.columns && this.props.columns.length > 0 && this.props.columns[0].dataKey === "running"){
                newList = newList.map((item, index) => {
                  return {...item, running:index+1}
                })
              }
              this.setState({ rows:newList, sortBy:sortBy, sortDirection:sortDirection})
            }}
            onSelectAll={this.props.onSelectAll}
          />
        </div>
      </Fragment>
    );
  }

}

export default connect(({app}) => {
	return {app};
}, {

})(injectIntl(TableList));
