import React from 'react';

const PageERP = React.lazy(() => import('../pages/erp'))

export const getAppSettings = () => {
  const backendModuleName = process.env.REACT_APP_BACKEND_MODULE_NAME;
  if (!backendModuleName) {
    throw new Error('Environment variables for app configuration were not set, or set improperly.');
  }

  try {
    const settings = require('./config').default[backendModuleName]
    if (!settings) {
      throw new Error('An error occurred while loading app configurations.');
    }
    return settings;
  } catch (error) {
    throw error;
  }
}
export default getAppSettings();

export class AppRoute extends React.Component {

  render() {
    switch(this.props.module_name) {
       case "dsa":
       case "dsa-sandbox": {
          return (<PageERP />)
       }
       case "dsapos":
       case "dsapos-sandbox": {
          return (<PageERP />)
       }
       default: {
         return null
       }
    }
  }

}
