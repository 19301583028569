import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import api from '../api';

const initialState = {
  loading:false,
  current_path:null,
  data : null
};

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REPORT_PERFORMING':
      return {...state, loading:true};
    case 'REPORT_SUCCESS':
      return {...state, loading:false , data : action.payload};
    case 'REPORT_FAILURE':
      NotificationManager.error(action.payload);
      return {...state, loading:false };
    case 'REPORT_CHANGE':
      return {...state, loading:false , data : null, current_path:(action.payload ? action.payload : state.current_path)};
    default:
      return {...state};
  }
};

// ############################## Internal Action ##########################
const actionInternal = (type, payload) => {
  return { type: type, payload: payload }
};

// ############################## Global Async Action ##########################
export const actionPerform = (type, payload) => {
  return { type: type , payload: payload}
};

// #######################################################################
function* asyncOperation({ payload }) {
  try {
    yield put(actionInternal("REPORT_PERFORMING",payload.action.toLowerCase()));
    const result = yield call(() => {return api("report/"+payload.action.toLowerCase(),payload.body)});
    if (result.error) {
        yield put(actionInternal("REPORT_FAILURE", result.error.message));
    } else {
        yield put(actionInternal("REPORT_SUCCESS",result.info));
    }
  } catch (err) {
    console.log(err.message);
    yield put(actionInternal("REPORT_FAILURE", "Unexpected Error"))
  }
}

// ############################## Watch Saga ##########################
function* watchOperation() {
  yield takeEvery('REPORT_OPERATION', asyncOperation);
}

export default function* rootSaga() {
    yield all([
        fork(watchOperation)
    ]);
}
