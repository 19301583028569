import * as React from 'react';
import { CSVLink } from "react-csv";

export default function KTDownloadCsv(props){

  const headers = props.columns.map((item, index) => {
    return {label:item.name, type:item.type, key:"field"+index}
  })

  const header_obj = headers.reduce((object, item) => {
    object[item.key] = item
    return object
  }, {})

  const data = props.data.map((item, index) => {
    return Object.keys(item).reduce((object, k) => {
      if(typeof item[k] === "string" && header_obj[k].type !== "date"){
        object[k] = (props.separator ? item[k] : `"`+item[k]+`"`)
      }else{
        object[k] = item[k]
      }
      return object
    }, {})
  })

  return (
    <div>
        <CSVLink
          filename={props.filename}
          className="csv-button"
          enclosingCharacter={``} separator={props.separator}
          headers={headers}
          data={data}>CSV</CSVLink>
    </div>
  )
}
