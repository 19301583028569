import React,{Fragment} from "react";
import styled from 'styled-components';
import {Grid} from '@material-ui/core';
import {KTIcon} from '../../KT'

const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 2px;
  line-height:1.5;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  img {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  ${props => props.bgColor && `
    background-color: `+props.bgColor+`;
  `}
  ${props => props.textColor && `
    color: `+props.textColor+`;
  `}
`;

class KTWCardBox extends React.Component {

  render(){

    const textColor = this.props.textColor ? this.props.textColor : (this.props.color ? "white" : "#606060")
    const bgColor = this.props.color ? this.props.color : "white"
    const hintColor = (textColor === "white" ? "white" : "#919191")

    return (
      <Container className={(this.props.blink ? "blink_me" : "")} bgColor={bgColor} textColor={textColor} style={{...this.props.style, width:(this.props.width ? this.props.width : "100%")}}>

        {(!this.props.type || this.props.type === "box") &&
        <Fragment>
          {this.props.images && this.props.images.map((item, index) => {
            return (
              <img key={index} src={item} alt="" style={{width:"100%"}} />
            )
          })}
          <div style={{padding:"10px 16px",cursor:(this.props.onClick ? "pointer" : "default")}} onClick={this.props.onClick} >
            <Grid container >
              {this.props.icon &&
              <Grid item xs={2} >
                <KTIcon vicon={this.props.icon} vicontype={(this.props.icontype ? this.props.icontype : "MaterialIcons")} size={32} color={this.props.iconColor ? this.props.iconColor : "white" } />
              </Grid>}
              <Grid item xs={(this.props.icon ? 10 : 12)} style={{marginBottom:(typeof this.props.value !== "undefined" || this.props.value === "" ? 4 : 0)}}>
                {this.props.caption && <div style={{...{color:textColor, textAlign:"right", fontSize:(this.props.captionSize ? this.props.captionSize : 14)},...this.props.captionStyle}}>{this.props.caption}</div>}
                <div style={{textAlign:"right",fontSize:(this.props.valueSize ? this.props.valueSize  : 18), lineHeight:2, marginBottom:(typeof this.props.value !== "undefined"  || this.props.value === "" ? 0 : 10)}}><b>{this.props.value}</b></div>
                {this.props.hint && <div style={{color:hintColor, textAlign:"right",fontSize:(this.props.hintSize ? this.props.hintSize  : 11),lineHeight:(this.props.hintLineHeight ? this.props.hintLineHeight : 1.2)}}>{this.props.hint}</div>}
                {this.props.hint2 && <div style={{color:hintColor, textAlign:"right",fontSize:(this.props.hintSize ? this.props.hintSize  : 11),lineHeight:(this.props.hintLineHeight ? this.props.hintLineHeight : 1.2)}}>{this.props.hint2}</div>}
                {this.props.hint3 && <div style={{color:hintColor, textAlign:"right",fontSize:(this.props.hintSize ? this.props.hintSize  : 11),lineHeight:(this.props.hintLineHeight ? this.props.hintLineHeight : 1.2)}}>{this.props.hint3}</div>}
              </Grid>
            </Grid>
          </div>
        </Fragment>}

        {this.props.type === "custom" &&
        React.Children.map(this.props.children, child => {
          return React.cloneElement(child, {
            width: this.props.width
          });
        }) }

      </Container>
    );

  }

}

export default KTWCardBox
