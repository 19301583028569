import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

import settings from '../../settings'

const styles = theme => ({
  loadingWrapper: {
    position: 'relative'
  },
  buttonProgressLarge: {
    color: settings.theme.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  buttonProgressSmall: {
    color: settings.theme.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  }
});

class KTButton extends React.Component {
  render() {
    //const { classes } = this.props;
    const style = (this.props.style ? this.props.style : {})
    if(this.props.flat){
      style.borderRadius = 0
    }
    if(this.props.bgColor){
      style.backgroundColor = this.props.bgColor
    }
    /*
    <div className={classes.loadingWrapper} >
      {this.props.loading && <CircularProgress size={this.props.size==="small" ? 16 : 24}
        className={(this.props.size==="small" ? classes.buttonProgressSmall : classes.buttonProgressLarge)} />}
    </div>
    */
    return (
      <Button variant="contained" color="primary" fullWidth size={this.props.size ? this.props.size : "large"}
        disabled={this.props.loading}
        style={style}
        onClick={this.props.onClick}  >
         {this.props.children}
      </Button>
    );
  }
}

export default withStyles(styles)(KTButton);
