import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import api from '../api';

const initialState = {
  action: {},
  filters: {},
  menu_filters: {},
  doc_option:{},
  mode: ""
};

export const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INFO_PERFORMING':
      return {...state, action: {...state.action, [action.payload.fdata]:true}};
    case 'INFO_SUCCESS':
      let result = {...state, action: {...state.action, [action.payload.fdata]:false} , [action.payload.fdata]:action.payload.info }
      if(action.payload.fdata.endsWith("_detail")){
        const info_name = action.payload.fdata.replace("_detail","")+"s"
        if(state[info_name]){
          const new_infos = state[info_name].map((item, index) => {
            if(item.id && (item.id === action.payload.info.id || item.id === action.payload.info.old_id)){
              return action.payload.info
            }else{
              return item
            }
          })
          result = {...result, [info_name]:new_infos}
        }
      }
      return result
    case 'INFO_FAILURE':
      if(!action.payload.error.startsWith("Session expired")){
        NotificationManager.error(action.payload.error);
      }
      return {...state, action: {...state.action, [action.payload.fdata]:false} };
    case 'INFO_APPLY_FILTER':
      return {...state, filters: {...state.filters, [action.payload.fdata]:action.payload.filter }};
    case 'INFO_APPLY_DOCOPTION':
      return {...state, doc_option: {...state.doc_option, [action.payload.fdata]:action.payload.doc_option }};
    case 'INFO_APPLY_MENUFILTER':
      return {...state, menu_filters: {...state.menu_filters, [action.payload.fdata]:action.payload.menu_filter }};
    case 'INFO_CLEAR_FILTER':
      delete state.filters[action.payload.fdata]
      return {...state, filters: state.filters}
    case 'INFO_CLEAR_MENUFILTER':
      delete state.menu_filters[action.payload.fdata]
      return {...state, menu_filters: state.menu_filters}
    case 'INFO_CLEAR':
      return {...state , [action.payload.fdata]: null };
    case 'INFO_CLEAR_ALL':
      return {...initialState};
    case 'INFO_UPDATE':
      return {...state , [action.payload.fdata]: action.payload.info };
    case 'INFO_MODE':
      return {...state , mode: action.payload.mode};
    default:
      return {...state};
  }
};

// ############################## Internal Action ##########################
const actionInternal = (type, payload) => {
  return { type: type, payload: payload }
};

// ############################## Global Async Action ##########################
export const actionPerform = (type, payload) => {
  return { type: type , payload: payload}
};

// #######################################################################
function* asyncOperation({ payload }) {
  try {
    yield put(actionInternal("INFO_PERFORMING", {fdata:payload.fdata}));
    const result = yield call(() => {return api(payload.action.toLowerCase(),payload.body)});
    if (result.error) {
      yield put(actionInternal("INFO_FAILURE", {fdata:payload.fdata, error:result.error.message}));
      if(payload.callback){
        payload.callback(false, result.error.message)
      }
    } else {
      yield put(actionInternal("INFO_SUCCESS",{fdata:payload.fdata, info:result.info}));
      if(payload.callback){
        payload.callback(true, result.info)
      }
    }
  } catch (err) {
    yield put(actionInternal("INFO_FAILURE", {fdata:payload.fdata, error:"Unexpected Error"}))
    if(payload.callback){
      payload.callback(false, "Unexpected Error")
    }
  }
}

// ############################## Watch Saga ##########################
function* watchOperation() {
  yield takeEvery('INFO_OPERATION', asyncOperation);
}

export default function* rootSaga() {
    yield all([
        fork(watchOperation)
    ]);
}
