import React from 'react';
import { useSelector} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import Item from './Item';
import {MagbizUser} from  '../../../utils/Magbiz';
import {translate} from  '../../../lang';

const styles = theme => {
  return {
    subTextColor:{
      fontSize: '0.7rem'
    }
  }
};

const SectionList = ({menu, onItemSelected, selectedItem, type, classes}) => {

  const userdata = useSelector(state => state.user.userdata)

  const permissionShow = MagbizUser.checkAuthorized(menu, userdata)

  if(permissionShow){
    return (
      <List component="nav"
        subheader={(menu.title ? <ListSubheader component="div" classes={{root: classes.subTextColor}}>{translate(menu.title)}</ListSubheader> : null)}>
        {menu.list.map((item, index) => (
          <Item key={index} index={index} type={type} item={item} selectedItem={selectedItem} onItemSelected={onItemSelected} />
        ))}
      </List>
    )
  }else{
    return null
  }
}

export default withStyles(styles, { withTheme: true })(SectionList)
