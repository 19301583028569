import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from 'classnames'
import {Typography, Radio} from "@material-ui/core";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const styles = theme => ({
  root: {
    padding:0,
    paddingLeft:10,
    paddingRight:5,
    color: "grey",
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  disabled:{
    color: "black!important",
  },
  paddingLeft5:{
    paddingLeft:5,
  }
});

class KTRadioField extends React.Component {

  _onChange = (event) => {
    this.setState({ selectedValue: event.target.value });
    if(this.props.onChange){
      this.props.onChange(event)
    }
  }

  render(){
    const { classes } = this.props
    const selectedValue = this.props.defaultValue

    let firstShowIndex = 0
    if(this.props.list){
      if(this.props.list.length > 0){
        if(this.props.list[0].label === ""){
          firstShowIndex = 1
        }
      }
    }

    return (
      <div style={{display:(typeof this.props.hideIfValue !== "undefined" && this.props.hideIfValue === selectedValue ? "none" : "block")}} >
        {this.props.list && this.props.list.map((item, index) => {

          let clsRoots = [classes.root]
          if(index === firstShowIndex){
            clsRoots.push(classes.paddingLeft5)
          }

          return (
            <span key={index} style={{display:(item.label !== "" ? "inline" : "none")}}>
              <Radio
                checked={selectedValue === item.value}
                onChange={this._onChange}
                value={item.value}
                name={this.props.name}
                disabled={this.props.disabled}
                aria-label={item.label}
                classes={{
                  root: classNames(clsRoots),
                  checked: classes.checked,
                  disabled: classes.disabled,
                }}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
              />
              <Typography style={{fontSize:"0.7rem", display:"inline-block"}}>
                {item.label}
              </Typography>
            </span>
          );
        })}
      </div>
    );
  }

}

export default withStyles(styles, { withTheme: true })(KTRadioField);
