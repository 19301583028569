import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import {Helmet} from "react-helmet";
import Swal from 'sweetalert2'

import {Grid, CircularProgress, Fab, Tooltip} from '@material-ui/core';
import {Edit as EditIcon, Save as SaveIcon, Delete as DeleteIcon, Undo as UndoIcon} from '@material-ui/icons';

import '../../theme/css/ReportViewer.css';
import settings from '../../settings'
import {MagbizGeneral, MagbizEvent} from '../../utils/Magbiz';
import {translate} from '../../lang'

import {actionPerform as actionPerformInfo} from '../../redux/Info'
import {actionPerform as actionPerformApp} from '../../redux/App'

import DocDefaultHeader from './DocDefaultHeader'
import DocDocumentHeader from './DocDocumentHeader'
import DocDefaultContent from './DocDefaultContent'
import DocTableContent from './DocTableContent'

class DetailForm extends React.Component {

  fetchFieldsValue = (detail) => {
    const fetchGroupValue = (objects, group) => {
      if(group.fields){
        const fields = Object.values(group.fields).reduce((objects1, ofield) => {
         let field = {...ofield}
         if(detail){
           if(field.type === "date" && detail[field.name] && detail[field.name].length > 10){
             field.value = detail[field.name].substr(0,10)
           }else if(field.type === "time" && !detail[field.name] && field.datefield && detail[field.datefield]){
             field.value = MagbizGeneral.right(detail[field.datefield],8)
           }else{
             field.value = detail[field.name]
           }
           if(field.onAssign){
             field.onAssign(field)
           }
         }
         objects1[field.name] = field
         return objects1
        }, {})

        return {...objects, ...fields}
      }else{
        return objects
      }
    }
    let fields = Object.values(this.props.module.detail.group_fields).reduce((objects, group) => {
      return fetchGroupValue(objects, group)
    }, {})

    if(this.props.module.detail.pages){
      const header_fields = Object.values(this.props.module.detail.pages).reduce((objects, page) => {
        if(page.header){
          if(page.header.left_fields){
            objects = fetchGroupValue(objects, page.header.left_fields)
          }
          if(page.header.right_fields){
            objects = fetchGroupValue(objects, page.header.right_fields)
          }
        }
        return objects
      }, {})
      fields = {...fields, ...header_fields}
    }

    if(this.props.module.onAssign){
      const results = this.props.module.onAssign(fields)
      if(results.length > 0){
        fields = results.reduce((objects, item) => {
            objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
            return objects
        }, fields)
      }
    }

    return fields
  }

  fetchTableFieldsValue = (detail) => {
    const table_fields = Object.values(this.props.module.detail.group_fields).reduce((objects, group) => {
      if(group.fieldType === "table"){
        let fieldRows = []
        if(detail && detail[group.tableName]){
          const me = this
          if(Array.isArray(detail[group.tableName])){
            detail[group.tableName].forEach(function (frow, rowIndex) {
              fieldRows.push(me.newTableRow(group.tableName, rowIndex, frow))
            })
          }else{
            console.log("Invalid table data", detail[group.tableName])
          }
        }
        objects[group.tableName] = fieldRows
      }
      return objects
    }, {})
    return table_fields
  }

  state = {
    mode : (this.props.selectId === "add" ? "add" : (this.props.module.startWithEdit ? "edit" : "view") ),
    fields: this.fetchFieldsValue(),
    table_fields: this.fetchTableFieldsValue(),
    table_summary:{},
    toggleFieldUpdate:false,
    isMapUpdate:false
  }

  componentDidMount(){
    this.props.actionPerformInfo("INFO_MODE", {mode:this.state.mode})
    if (this.props.module.onPageLoad) {
      let fields = {...this.state.fields}
      fields = this.props.module.onPageLoad(this.state.fields).reduce((objects, item) => {
          objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
          return objects
      }, fields)
      this.setState({fields:fields, toggleFieldUpdate:!this.state.toggleFieldUpdate})
    }
    if(this.props.selectId !== "add"){
      this.loadData()
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    const detail_name = this.props.module.detail.name
    if(this.props.info[detail_name] !== nextProps.info[detail_name]){
      const fields = this.fetchFieldsValue(nextProps.info[detail_name])
      const table_fields = this.fetchTableFieldsValue(nextProps.info[detail_name])
      const table_summary = (nextProps.info[detail_name] && nextProps.info[detail_name]["promotions"] ? {promotions:nextProps.info[detail_name]["promotions"]} : {})
      this.setState({fields:fields, table_fields:table_fields, table_summary:table_summary})
      return false
    }
    if(this.props.module.mapData && this.props.app.geos && nextProps.app.geos.polygon && this.state.fields.polygon_geofield && this.props.app.geos.polygon !== nextProps.app.geos.polygon){
      this.setState({isMapUpdate:true})
      this._onFieldChange({target:{name:"polygon_geofield", value:nextProps.app.geos.polygon.polygon_geofield}}, nextProps.app.geos.polygon)
      return false
    }
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.props.info.action[detail_name] !== nextProps.info.action[detail_name]){return true}
    if(this.props.info.action[detail_name+"_operation"] !== nextProps.info.action[detail_name+"_operation"]){return true}
    if(this.props.module !== nextProps.module){return true}
    if(this.state !== nextState){
      if(this.state.mode !== nextState.mode){
        this.props.actionPerformInfo("INFO_MODE", {mode:nextState.mode})
      }
      return true
    }
    if(this.props.width !== nextProps.width){return true}
    return false
  }

  newTableRow = (tableName, rowIndex, fvalues) => {
    let fieldRow = {}
    this.props.module.detail.group_fields[tableName].columns.forEach(function (col, colIndex) {
      let field = {...col}

      if(fvalues && fvalues[field.name]){
        field.value = fvalues[field.name]
      }
      field.name = tableName+"_"+field.name+"["+rowIndex+"]"
      fieldRow[field.name] = field
    })
    return fieldRow
  }

  setFieldValue = (name, value, optionAsync) => {
    let toggleFieldUpdate = this.state.toggleFieldUpdate
    if(name.endsWith("]")){
      const tableName = name.substring(0, name.indexOf("_"))
      const rowIndex = name.substring(name.indexOf("[")+1,name.indexOf("]"))
      let table_fields = this.state.table_fields
      let table_summary = this.state.table_summary
      let fields = this.state.fields
      let fieldsAtIndex = table_fields[tableName][rowIndex]
      if(typeof fieldsAtIndex === "undefined" && value !== ""){
        fieldsAtIndex = this.newTableRow(tableName, rowIndex)
        table_fields[tableName][rowIndex] = fieldsAtIndex
      }
      if(typeof fieldsAtIndex !== "undefined"){
        fieldsAtIndex[name].value =  value
        let onTableChangeFunc = fieldsAtIndex[name].onChange
        if(this.props.module.onTableChange){
          onTableChangeFunc = this.props.module.onTableChange
        }
        if(onTableChangeFunc){
          const returnValues = onTableChangeFunc(name, fieldsAtIndex, table_fields, fields)
          if(returnValues){
            table_fields[tableName][rowIndex] = returnValues.field_table_list.reduce((objects, item) => {
                objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
                return objects
            }, fieldsAtIndex)
            if(returnValues.field_list){
              fields = returnValues.field_list.reduce((objects, item) => {
                  objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
                  return objects
              }, fields)
            }
            if(returnValues.summary){
              table_summary = returnValues.summary
            }
          }
          toggleFieldUpdate = !toggleFieldUpdate
        }
        this.setState({table_fields:table_fields, table_summary:table_summary, fields:fields, toggleFieldUpdate:toggleFieldUpdate})
      }
    }else{
      let fields = {...this.state.fields, [name] : {...this.state.fields[name], value: value} }
      let onChangeFunc = this.state.fields[name].onChange
      if(this.props.module.onChange){
        onChangeFunc = this.props.module.onChange
      }
      if(onChangeFunc){
        fields = onChangeFunc(name, fields, optionAsync).reduce((objects, item) => {
            objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
            return objects
        }, fields)
        toggleFieldUpdate = !toggleFieldUpdate
      }

      if(optionAsync && optionAsync.sync_detail){
        const table_fields = this.fetchTableFieldsValue(optionAsync.sync_detail)
        this.setState({fields:fields, table_fields:table_fields,toggleFieldUpdate:toggleFieldUpdate})
      }else{
        this.setState({fields:fields, toggleFieldUpdate:toggleFieldUpdate})
      }

    }
  }

  _onTableRowClick = async (tableName, item, index) => {
    let optionAsync = null
    if(this.props.module.onTableRowClick){
      optionAsync = await this.props.module.onTableRowClick(tableName, item, index)
      if(optionAsync){
        let toggleFieldUpdate = this.state.toggleFieldUpdate
        let fields = {...this.state.fields}
        fields = optionAsync.reduce((objects, item) => {
            objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
            return objects
        }, fields)
        toggleFieldUpdate = !toggleFieldUpdate
        this.setState({fields:fields, toggleFieldUpdate:toggleFieldUpdate})
      }
    }
  }

  _onFieldChange = async (event, objectInfo) => {
    const target = event.target
    let optionAsync = null
    if(this.props.module.onChangeAsync){
      optionAsync = await this.props.module.onChangeAsync(target.name, target.value, objectInfo, this.state.fields)
    }
    this.setFieldValue(target.name, target.value, optionAsync)
  }

  _onDateTimeChange = async (dateObj) => {
    let optionAsync = null
    if(this.props.module.onChangeAsync){
      optionAsync = await this.props.module.onChangeAsync(dateObj.name, dateObj.value, null, this.state.fields)
    }
    this.setFieldValue(dateObj.name, dateObj.value, optionAsync)
  }

  _onFieldSelect = (name, selectId) => {
    let toggleFieldUpdate = this.state.toggleFieldUpdate
    if(name.endsWith("]")){
      const tableName = name.substring(0, name.indexOf("_"))
      const rowIndex = name.substring(name.indexOf("[")+1,name.indexOf("]"))
      let table_fields = this.state.table_fields
      let table_summary = this.state.table_summary
      let fields = this.state.fields
      let fieldsAtIndex = table_fields[tableName][rowIndex]
      if(typeof fieldsAtIndex === "undefined" && selectId !== ""){
        fieldsAtIndex = this.newTableRow(tableName, rowIndex)
        table_fields[tableName][rowIndex] = fieldsAtIndex
      }
      if(typeof fieldsAtIndex !== "undefined"){
        fieldsAtIndex[name].value = selectId
        let onTableSelectFunc = fieldsAtIndex[name].onSelect
        if(this.props.module.onTableSelectFunc){
          onTableSelectFunc = this.props.module.onTableSelectFunc
        }
        if(fieldsAtIndex[name].onSelect){
          const returnValues = onTableSelectFunc(name, selectId, table_fields, fields, fieldsAtIndex)
          if(returnValues){
            table_fields[tableName][rowIndex] = returnValues.field_table_list.reduce((objects, item) => {
                objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
                return objects
            }, fieldsAtIndex)
            if(returnValues.field_list){
              fields = returnValues.field_list.reduce((objects, item) => {
                  objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
                  return objects
              }, fields)
            }
            if(returnValues.summary){
              table_summary = returnValues.summary
            }
          }
          toggleFieldUpdate = !toggleFieldUpdate
        }
        this.setState({table_fields:table_fields, table_summary:table_summary, fields:fields, toggleFieldUpdate:toggleFieldUpdate})
      }
    }else{
      let fields = this.state.fields
      fields[name].value = selectId
      if(this.state.fields[name].onSelect){
        fields = this.state.fields[name].onSelect(name, selectId).reduce((objects, item) => {
            objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
            return objects
        }, fields)
        toggleFieldUpdate = !toggleFieldUpdate
      }
      this.setState({fields:fields, toggleFieldUpdate:toggleFieldUpdate})
    }
  }

  _onFileUploadImageChange = (name, filepathurl, filepath) => {
    this.setState({fields:{...this.state.fields, [name] : {...this.state.fields[name], value: filepath} }})
  }

  getItemValue = (item) => {
    let value = item.value
    if(item.type.startsWith("decimal")){
      if(typeof item.value === "string"){
        value = parseFloat(item.value.replace(",",""))
        if(isNaN(value)) value = 0
      }
    }else if(item.type === "int"){
      if(typeof item.value === "string"){
        value = parseInt(item.value.replace(",","").replace(/[^\d.-]/g, ''))
        if(isNaN(value)) value = 0
      }
    }else if(item.type === "time"){
      if(typeof item.value === "string"){
        if(item.value.length === 5){
          value = item.value + ":00"
        }
      }
      if(!value){
        value = "00:00:00"
      }
    }
    return value
  }

  getGeoItemValue = (item) => {
    return this.props.app.geos[item.name]
  }

  _onSaveButtonClick = (event) => {
    //if(this.props.module.confirmSave){
      const tlang = this.props.intl.formatMessage;
      Swal.fire({
        title: tlang({id:"confirmation"}),
        text: tlang({id:"question_confirm_save"}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: tlang({id:"no"}),
        confirmButtonText: tlang({id:"yes_confirm"})
      }).then((result) => {
        if(result.value){
          this.saveData()
        }
      })
    //}else{
    //  this.saveData()
    //}
  }

  saveData = async () => {
    let incomplete = false
    let requiredText = ""
    let fields =  Object.values(this.state.fields).reduce((object, item) => {
      if(item.noSave) return object
      let value = this.getItemValue(item)
      if(item.required){
        if(value === "" || value === 0){
          incomplete = true
          if(requiredText === "" && item.requiredText) requiredText = item.requiredText
        }
      }
      if(item.datefield && typeof object[item.datefield] !== "undefined"){
        return {...object, [item.datefield] : object[item.datefield]+" "+value}
      }
      if(item.prefix && !value.startsWith(item.prefix)){
        value = item.prefix+value
      }
      return {...object, [item.name] : value}
    }, {})
    if(incomplete){
      if(requiredText){
        MagbizEvent.alert(requiredText)
        return
      }else{
        MagbizEvent.alert(translate("error.incomplete_form"))
        return
      }
    }

    fields = Object.keys(this.state.table_fields).reduce((object, key) => {
      const fobj = this.state.table_fields[key].reduce((obj, items) => {
        let requireCompleted=true
        let itemObj = {}
        for(let k in items){
          const item = items[k]
          if(!Boolean(item.noSave) && item.name){
            if(!Boolean(item.noSaveRowIfBlank) || item.value !== ""){
              const value = this.getItemValue(item)
              if(item.required){
                if(value === "" || value === 0){
                  requireCompleted=false
                }
              }
              itemObj = {...itemObj, [item.name.substring(key.length+1, item.name.indexOf("[")).replace("item_","")] : value }
            }
          }
        }
        if(requireCompleted){
          obj.push(itemObj)
        }
        return obj
      }, [])
      return {...object, ["list_"+key] : fobj}
    }, fields)


    if(this.state.table_summary && this.state.table_summary.promotions){
      fields.list_promotions = this.state.table_summary.promotions
    }

    if(this.props.module.geos){
      const geofields = Object.values(this.props.module.geos).reduce((object, item) => {
        if(item.noSave) return object
        const value = this.getGeoItemValue(item)
        if(typeof value === "undefined") return object
        if(this.props.info[this.props.module.detail.name] && value === this.props.info[this.props.module.detail.name][item.name] && value !== "") return object
        return {...object, [item.name] : value}
      }, {})
      fields = {...fields, ...geofields}
    }

    if(this.props.module.onBeforeSave){
      fields = await this.props.module.onBeforeSave(fields, this.state.isMapUpdate)
    }

    if(this.props.module.detail.additional_field){
      fields = {...fields,...this.props.module.detail.additional_field}
    }

    this.props.actionPerformInfo("INFO_OPERATION", {action:this.props.module.detail.actionPerform.save, body:fields, fdata:this.props.module.detail.name, callback:((success) => {

      if(success){
        if(this.state.mode === "add"){
          if(this.props.reload){
            this.props.reload()
          }
        }
        if(this.props.module.backOnSave && this.props.back){
          if(!(this.state.mode === "add" && Boolean(this.props.module.backOnSaveExceptForAdd))){
            MagbizGeneral.delay(() => {
              this.props.back()
            }, 1000)
          }
        }
        if(this.state.isMapUpdate){
          this.props.actionPerformApp("APP_SETTING_MAP", {...this.props.app.map, isChanged:true})
          this.setState({mode:"view", isMapUpdate:false})
        }else{
          if(this.state.mode === "add" && Boolean(this.props.module.editAfterAdd)){
            this.setState({mode:"edit"})
          }else{
            this.setState({mode:"view"})
          }
        }
        if(this.props.module.detail.actionPerform.saveCallback){
          this.props.module.detail.actionPerform.saveCallback()
        }
      }
    })})
  }

  _onEditButtonClick = (event) => {
    this.setState({mode:"edit"})
  }

  _onDeleteButtonClick = (event) => {
    const tlang = this.props.intl.formatMessage;
    Swal.fire({
      title: tlang({id:"confirmation"}),
      text: tlang({id:"question_confirm_"+(this.props.module.type === "document" ? "cancel" : "delete")}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: tlang({id:"no"}),
      confirmButtonText: tlang({id:"yes_confirm"})
    }).then((result) => {
      if (result.value) {
        let fields = {
           [this.props.module.dataId] : this.state.fields[this.props.module.dataId].value
        }
        if(this.props.module.detail.additional_field){
          fields = {...fields,...this.props.module.detail.additional_field}
        }
        this.props.actionPerformInfo("INFO_OPERATION", {action:this.props.module.detail.actionPerform.delete, body:fields, fdata:this.props.module.detail.name+"_operation", callback:((success) => {
          if(success){
            if(this.props.reload){
              this.props.reload()
            }
            if(this.props.back){
              this.props.back()
            }
            //this.props.history.push("/"+settings.name+"/"+this.props.module.name)
            //this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.detail.name})
            if(this.props.module.detail.actionPerform.deleteCallback){
              this.props.module.detail.actionPerform.deleteCallback()
            }
          }
        })})
      }
    })

  }

  _onUndoButtonClick = (event) => {
    this.setState({mode:"view"})
  }

  loadData = () => {
    let filter = {[this.props.module.dataId]:this.props.selectId}
    if(this.props.module.detail.additional_filter){
      filter = {...filter,...this.props.module.detail.additional_filter}
    }
    this.props.actionPerformInfo("INFO_OPERATION", {action:this.props.module.detail.actionPerform.load, body:filter, fdata:this.props.module.detail.name})
  }

  render() {
    //console.log("Render DetailForm ")
    const {detail} = this.props.module
    let pages = {...detail.pages}
    const pageSize = detail.page
    const paperSize = (detail.viewPaperSize && this.state.mode === "view" ? detail.viewPaperSize :  pageSize)
    const pageInnerStyle = {}

    if(!pages){
      pages = {
        page1 : {
          header:(detail.header ? detail.header : {}),
          content:{
            groups: Object.keys(detail.group_fields).reduce((objects, group) => {
              objects.push(group)
              return objects
            }, [])
          }
        }
      }
    }

    // Auto multi page supported
    if(this.state.mode === "view" && detail.paging && detail.pages.page1 && detail.group_fields.detail && detail.group_fields.detail.fieldType === "table"){
      const promotion_length = (this.state.table_summary && this.state.table_summary.promotions ? this.state.table_summary.promotions.length : 0)
      const pagerow = parseFloat((detail.group_fields.detail.pageRow ? detail.group_fields.detail.pageRow : detail.group_fields.detail.tableRow))
      if(this.state.table_fields && this.state.table_fields.detail.length+promotion_length > pagerow){
        const defaultgroups = [...detail.pages.page1.content.groups]
        pages = {}
        const pagenum = Math.ceil((this.state.table_fields.detail.length+promotion_length)/pagerow)
        for(let k=1;k<=pagenum;++k){
          const thepage = {...detail.pages.page1,
            pageRange: {start:(k-1)*pagerow, end:k*pagerow, isLast:(k === pagenum ? true : false)},
            footer:{text:"Page "+k+"/"+pagenum, style:{fontSize:9, textAlign:"right"}},
            isLastPage: (k === pagenum ? true : false)
          }
          if(detail.pagingHideContent && k !== pagenum){
            thepage.content = {...detail.pages.page1.content, groups:defaultgroups.filter(item => !MagbizGeneral.includes([item], detail.pagingHideContent))}
          }else{
            thepage.content = {...detail.pages.page1.content, groups:defaultgroups}
          }
          pages["page"+k] = thepage
        }
      }
    }

    //Page copy
    if(this.state.mode === "view" && detail.pageCopy && detail.pages.page1) {
      pages = {}
      pageInnerStyle.position = "relative"
      for(let k=0;k<detail.pageCopy.labels.length;++k){
        const pageNum = (k+1)
        const thepage = {...detail.pages.page1,
          pageLabel:{text:detail.pageCopy.labels[k], style:detail.pageCopy.labelStyle}
        }
        pages["page"+pageNum] = thepage
      }
    }

    return (
      <div style={{width:this.props.width, height:this.props.height, overflow:"auto"}}>
        <div  className={`document-container `+(this.props.module.detail.page_container_style ? this.props.module.detail.page_container_style : "" )}  ref={el => {
            if(Boolean(this.props.module.permission.print) && this.state.mode === "view"){
              this.props.actionPerformApp("APP_SETUP_INTERFACE", {...this.props.app.interface, printRef:el})
            }else{
              this.props.actionPerformApp("APP_SETUP_INTERFACE", {...this.props.app.interface, printRef:null})
            }
          }} >

          {(this.props.info.action[detail.name] || this.props.info.action[detail.name+"_operation"]) &&
          <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'400px'}} >
            <CircularProgress />
          </Grid>}

          {!this.props.info.action[detail.name] && !this.props.info.action[detail.name+"_operation"] &&
          <Fragment>

            <Helmet>
              <title>{detail.title}</title>
            </Helmet>

            {pages && Object.values(pages).map((page, pageIndex) => {
              if(page.header){
                if(!Boolean(page.header.title)) page.header.title = detail.title
                if(!Boolean(page.header.title2)) page.header.title2 = detail.title2
                if(!Boolean(page.header.subtitle)) page.header.subtitle = detail.subtitle
              }
              page.content.group_fields = page.content.groups.reduce((objects, group) => {
                objects[group] = detail.group_fields[group]
                return objects
              }, {})

              let pageCopy = null
              if(paperSize === "A4" && pageSize === "A5landscape" && this.state.mode === "view"){

                if(pageIndex%2 === 0 && pageIndex < Object.keys(pages).length - 1){
                  pageCopy = pages["page"+(pageIndex+2)]

                  if(pageCopy.header){
                    if(!Boolean(pageCopy.header.title)) pageCopy.header.title = detail.title
                    if(!Boolean(pageCopy.header.title2)) pageCopy.header.title2 = detail.title2
                    if(!Boolean(pageCopy.header.subtitle)) pageCopy.header.subtitle = detail.subtitle
                  }
                  pageCopy.content.group_fields = pageCopy.content.groups.reduce((objects, group) => {
                    objects[group] = detail.group_fields[group]
                    return objects
                  }, {})

                }else{
                  return null
                }
              }

              return (
                <div key={pageIndex} className={`document `+(paperSize)} style={page.style} >
                  <div className={`document-page `+(paperSize)} style={pageInnerStyle}>

                    {page.pageLabel &&
                    <div style={page.pageLabel.style}>{page.pageLabel.text}</div>}

                    {page.header && (page.header.type === "default" || MagbizGeneral.value(page.header.type) === "") &&
                    <DocDefaultHeader header={page.header} width={this.props.width} mode={this.state.mode} fields={this.state.fields} mytheme={this.props.module.mytheme}
                        onFieldChange={this._onFieldChange}
                        onDateTimeChange={this._onDateTimeChange}
                        onFieldSelect={this._onFieldSelect}
                    />}

                    {page.header && page.header.type === "document" &&
                    <DocDocumentHeader header={page.header} width={this.props.width} mode={this.state.mode} fields={this.state.fields} mytheme={this.props.module.mytheme}
                      onFieldChange={this._onFieldChange}
                      onDateTimeChange={this._onDateTimeChange}
                      onFieldSelect={this._onFieldSelect}
                    />}

                    <Grid container >
                      {page.content.group_fields && Object.values(page.content.group_fields).map((groupObj, groupIndex) => {

                        return (
                          <Fragment key={groupIndex} >
                            {groupObj.fieldType === "table" &&
                              <DocTableContent groupObj={groupObj} width={this.props.width} mode={this.state.mode}
                              table_fields={this.state.table_fields} table_summary={this.state.table_summary} fields={this.state.fields} mytheme={this.props.module.mytheme}
                              height={groupObj.height}
                              onFieldChange={this._onFieldChange}
                              onDateTimeChange={this._onDateTimeChange}
                              onFieldSelect={this._onFieldSelect}
                              onTableRowClick={(item, index) => this._onTableRowClick(groupObj.tableName, item, index)}
                              pageRange={page.pageRange}
                              paging={detail.paging}
                              isLastPage={page.isLastPage}
                              />}

                            {(groupObj.fieldType === "default" || MagbizGeneral.value(groupObj.fieldType) === "") && !(groupObj.hideOnView && this.state.mode === "view") && Object.keys(groupObj).length > 0 &&
                              <DocDefaultContent groupObj={groupObj} width={this.props.width} mode={this.state.mode} fields={this.state.fields} mytheme={this.props.module.mytheme}
                              onFieldChange={this._onFieldChange}
                              onDateTimeChange={this._onDateTimeChange}
                              onFieldSelect={this._onFieldSelect}
                              onFileUploadImageChange={this._onFileUploadImageChange}
                              onFire={this.props.module.onFire}
                              />}

                          </Fragment>
                        )
                      })}
                    </Grid>

                    {page.footer &&
                    <div style={page.footer.style}>{page.footer.text}</div>}

                    {pageCopy &&
                    <div style={{marginTop:(detail.pageCopy.marginSep ? detail.pageCopy.marginSep : 0), marginBottom:(detail.pageCopy.marginSep ? detail.pageCopy.marginSep : 0), borderTop:"1px dashed grey", position:"relative"}}>

                      {pageCopy.pageLabel &&
                      <div style={pageCopy.pageLabel.style}>{pageCopy.pageLabel.text}</div>}

                      {pageCopy.header && (pageCopy.header.type === "default" || MagbizGeneral.value(pageCopy.header.type) === "") &&
                      <DocDefaultHeader header={pageCopy.header} width={this.props.width} mode={this.state.mode} fields={this.state.fields}
                          onFieldChange={this._onFieldChange}
                          onDateTimeChange={this._onDateTimeChange}
                          onFieldSelect={this._onFieldSelect}
                      />}

                      {pageCopy.header && pageCopy.header.type === "document" &&
                      <DocDocumentHeader header={pageCopy.header} width={this.props.width} mode={this.state.mode} fields={this.state.fields}
                        onFieldChange={this._onFieldChange}
                        onDateTimeChange={this._onDateTimeChange}
                        onFieldSelect={this._onFieldSelect}
                      />}


                      <Grid container >
                        {pageCopy.content.group_fields && Object.values(pageCopy.content.group_fields).map((groupObj, groupIndex) => {

                          return (
                            <Fragment key={groupIndex} >
                              {(groupObj.fieldType === "default" || MagbizGeneral.value(groupObj.fieldType) === "") && !(groupObj.hideOnView && this.state.mode === "view") && Object.keys(groupObj).length > 0 &&
                                <DocDefaultContent groupObj={groupObj} width={this.props.width} mode={this.state.mode} fields={this.state.fields}
                                onFieldChange={this._onFieldChange}
                                onDateTimeChange={this._onDateTimeChange}
                                onFieldSelect={this._onFieldSelect}
                                onFileUploadImageChange={this._onFileUploadImageChange}
                                onFire={this.props.module.onFire}
                                />}
                            </Fragment>
                          )
                        })}
                      </Grid>

                      {pageCopy.footer &&
                      <div style={pageCopy.footer.style}>{pageCopy.footer.text}</div>}

                    </div>}

                  </div>
                </div>
              )
            })}

            {Boolean(this.props.module.permission.edit) && this.state.mode === "edit" && false &&
            <Tooltip title="Cancel Editing">
              <Fab className="no-print" color="primary" aria-label="Cancel Editing" size="large"
                style={{position:"fixed", bottom:90, left: this.props.width - 20 - 56, backgroundColor:(this.props.module.mytheme ? settings.theme.mytheme.button : null)}} onClick={this._onUndoButtonClick} >
                  <UndoIcon />
              </Fab>
            </Tooltip>}
            {Boolean(this.props.module.permission.edit) && ["add", "edit"].indexOf(this.state.mode) > -1 &&
            <Tooltip title="Save">
              <Fab className="no-print" color="primary" aria-label="Save" size="large"
                  style={{position:"fixed", bottom:20, left: this.props.width - 20 - 56, backgroundColor:(this.props.module.mytheme ? settings.theme.mytheme.button : null)}} onClick={this._onSaveButtonClick} >
                  <SaveIcon />
              </Fab>
            </Tooltip>}

            {Boolean(this.props.module.permission.delete) && this.state.mode === "view" && this.props.module.permission.allowCondition("delete",this.props.info[this.props.module.detail.name],this.state.mode) &&
            <Tooltip title="Cancel">
              <Fab className="no-print" color="primary" aria-label="Cancel" size="large"
                  style={{position:"fixed", bottom:20, left: this.props.width - 20 - 56 - 20 - 56, backgroundColor:(this.props.module.mytheme ? settings.theme.mytheme.button : null)}} onClick={this._onDeleteButtonClick} >
                  <DeleteIcon />
              </Fab>
            </Tooltip>}

            {Boolean(this.props.module.permission.edit) && this.state.mode === "view" && this.props.module.permission.allowCondition("edit",this.props.info[this.props.module.detail.name],this.state.mode) &&
            <Tooltip title="Edit">
              <Fab className="no-print" color="primary" aria-label="Edit" size="large"
                  style={{position:"fixed", bottom:20, left: this.props.width - 20 - 56, backgroundColor:(this.props.module.mytheme ? settings.theme.mytheme.button : null)}} onClick={this._onEditButtonClick} >
                  <EditIcon />
              </Fab>
            </Tooltip>}

          </Fragment>}

        </div>
      </div>
    );
  }

}

export default withRouter(connect(({app, user, info}) => {
	return {app, user, info};
}, {
  actionPerformInfo,
  actionPerformApp
})(injectIntl(DetailForm)));
