import React from 'react';
import Measure from 'react-measure';

import {MagbizGeneral} from  '../../utils/Magbiz';

class KTTableView extends React.Component {

  state = {
    width:0
  }

  _onResize = (contentRect) => {
    if(this.state.width === 0){
      this.setState({width: parseInt(MagbizGeneral.round(contentRect.bounds.width,0))})
    }
  };

  render() {
    const createMarkup = (text) => {
      return {__html: text};
    }

    const info = this.props.value

    let nowrap = false
    let paddingLR = 5
    let paddingTB = 5
    let header_styles = {
      backgroundColor:"#f2f2f2",
      fontWeight:"bold",
      padding:5,
      fontSize:11
    }
    let content_styles = {
      borderBottom:"1px solid #fafafa",
      padding:5,
      fontSize:10,
      overflow:"hidden"
    }
    if(this.props.nowrap){
      nowrap = this.props.nowrap
    }
    if(this.props.paddingLR){
      paddingLR = this.props.paddingLR
    }
    if(this.props.paddingTB){
      paddingTB = this.props.paddingTB
    }
    if(this.props.headerStyle){
      header_styles = this.props.headerStyle
    }
    if(this.props.contentStyle){
      content_styles = this.props.contentStyle
    }
    let tbodyStyle = {...{display:"block", overflow:"auto", width:this.state.width}, ...this.props.tbodyStyle}

    let colWidths = []
    if(info.cols){
      for(let k=0;k<info.cols.length;++k){
        let width = info.cols[k].width
        if(typeof width === "string"){
          width = parseInt(MagbizGeneral.round(parseInt(width.replace("%",""))*(this.state.width-info.cols.length+1)/100,0))
        }
        colWidths[k] = width
      }
    }

    return (
      <Measure bounds onResize={this._onResize} >
        {({ measureRef }) =>
        <div ref={measureRef}  style={this.props.style}>
          <table style={{width:"100%", tableLayout: "fixed", borderCollapse: "collapse", borderSpacing: 0}}>
            <thead>
              <tr>
               {info.cols && info.cols.map((item, index) => {
                 return (
                   <th key={index} style={{...header_styles, width:colWidths[index], textAlign:(item.textAlign ? item.textAlign : "center") }}>
                    <div style={{width:colWidths[index]-(paddingLR*2), paddingLeft:paddingLR, paddingRight:paddingLR, paddingTop:paddingTB, paddingBottom:paddingTB}}>
                      {item.name}
                    </div>
                  </th>
                 )
               })}
             </tr>
            </thead>
            <tbody style={tbodyStyle}>
            {info.rows && info.rows.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map((col, cindex) => {
                    let rpaddingLR = paddingLR
                    if(typeof info.cols[cindex].paddingLR !== "undefined"){
                      rpaddingLR = info.cols[cindex].paddingLR
                    }
                    let rpaddingTB = paddingTB
                    if(typeof info.cols[cindex].paddingTB !== "undefined"){
                      rpaddingTB = info.cols[cindex].paddingTB
                    }
                    return (
                      <td key={cindex}
                        style={{...content_styles, width:colWidths[cindex], textAlign:(info.cols[cindex].textAlign ? info.cols[cindex].textAlign : "left")}} >
                        <div style={{width:colWidths[cindex]-(rpaddingLR*2), overflow:"hidden", whiteSpace:(nowrap ? "nowrap" : "normal"),
                            paddingLeft:rpaddingLR, paddingRight:rpaddingLR, paddingTop:rpaddingTB, paddingBottom:rpaddingTB }}
                          dangerouslySetInnerHTML={createMarkup(col.value)}/>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>}
      </Measure>

    );
  }
}

export default KTTableView;
