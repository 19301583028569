import React from 'react';
import SectionList from 'react-virtualized-sectionlist';

const renderHeader = ({title, sectionIndex, key, style, isScrolling, isVisible, parent}) => {
  return (
    <div key={key} className="list--header" style={style}>
      <h4>{title}ll</h4>
    </div>
  );
};

const renderRow = ({item, sectionIndex, rowIndex, key, style, isScrolling, isVisible, parent}) => {
  return (
    <div key={key} className="list--item" style={style}>
      <p>{item}</p>
    </div>
  );
};

const ROW_HEIGHT = 30;

const sections = [
  { title: 'Group 1', data: ['Item 1 of Group 1', 'Item 2 of Group 1', 'Item 3 of Group 1'] },
  { title: 'Group 2', data: ['Item 1 of Group 2', 'Item 2 of Group 2', 'Item 3 of Group 2'] },
  { title: 'Group 3', data: ['Item 1 of Group 3', 'Item 2 of Group 3', 'Item 3 of Group 3'] },
];

const UISectionList = () => {
  return (
    <SectionList
      width={300}
      height={250}
      sections={sections}
      sectionHeaderRenderer={renderHeader}
      sectionHeaderHeight={ROW_HEIGHT}
      rowHeight={ROW_HEIGHT}
      rowRenderer={renderRow} />
  );
};

export default UISectionList
