module.exports = {
	"lang" : "en",

	"button.login":"Login",
	"button.query":"Query",
	"button.upload":"Upload",
	"button.checking": "Check",
	"button.verified": "Approve",
	"button.import": "Import",
	"button.cancel": "Cancel",
	"button.restore": "Restore",
	"status": "Status",
	"status.All": "All",
	"status.Active" : "Active",
	"status.Inactive" : "In-active",
	"status.Draft": "Draft",
	"status.Checking": "Checking",
	"status.Verified": "Approved",
	"status.Cancelled": "Cancelled",
	"report.data_date": "Date as of",

	"search_please_type" : "Type search...",
	"search_not_found" : "No data...",
	"confirmation" : "Confirmation",
	"yes_confirm" : "Yes, confirm",
	"no" : "No",
	"question_confirm_cancel" : "Do you want to cancel this entry ?",
	"question_confirm_delete" : "Do you want to delete this entry ?",
	"question_confirm_operation" : "Operation comfirmation",

	"save": "Save",
	"add": "Add",
	"update": "Updae",
	"cancel": "Cancel",
	"print": "Print",

	"monthly" : "Month / Year",
	"date":"Date",
	"time":"Time",
	"datetime":"Date/Time",
	"from_date":"From Date",
	"to_date":"To Date",
	"from_time":"From Time",
	"to_time":"To Time",
	"year": "Year",
	"day": "Day",
	"hour": "Hr",
	"min": "Min",
	"second": "Sec",
	"hour_abbr" : "h",
	"minute_abbr" : "m",
	"second_abbr" : "s",

	"district" : "District",
	"city" : "City",
	"province" : "Province",
	"portal" : "Portal",

	"detail_of" : "Detail of",
	"all":"All",
	"type":"Type",
	"username":"Username",
	"password":"Password",

	"baht": "Baht",
	"before": "Before",
	"now": "Now",
	"today": "Today",
	"last_weekly": "Last Week",
	"last_monthly": "Last Month",
	"please_specify":"Please specify",
	"not_specific":"Not specific",
	"no_data": "No data",
	"loading": "Loading, please wait...",
	"search": "Search",
	"remark" : "Remark",
	"number" : "Number",
	"entry" : "Entry"
}
