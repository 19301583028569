import { addLocaleData } from 'react-intl';
import th from 'react-intl/locale-data/th';
import en from 'react-intl/locale-data/en';

import thMessages from './messages/th';
import enMessages from './messages/en';
import settings from '../settings';

const langsPack = {
  erp : {
    th : require('./erp/th'),
    en : require('./erp/en'),
  },
}

const thLang = {
    messages: {...thMessages},
    locale: 'th-TH',
    data: th
};

const enLang = {
    messages: {...enMessages},
    locale: 'en-EN',
    data: en
};

let langName = settings.name
if(settings.name === "dsa" || settings.name === "dsa-sandbox" || settings.name === "dsapos" || settings.name === "dsapos-sandbox"){
  langName = "erp"
}
thLang.messages = {...thLang.messages, ...langsPack[langName].th}
enLang.messages = {...enLang.messages, ...langsPack[langName].en}

const appLang = {
    th: thLang,
    en: enLang,
};

addLocaleData(appLang.th.data);
addLocaleData(appLang.en.data);

export const translate = (key) => {
  const languageTag = (localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "th")
  const translations = appLang[languageTag].messages
  return translations[key]
}

export const tlang = (obj) => {
  const languageTag = (localStorage.hasOwnProperty("lang") ? localStorage.getItem("lang") : "th")
  const translations = appLang[languageTag].messages
  return translations[obj.id]
}

export default appLang;
