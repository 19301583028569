import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import {useUserOperation} from '../utils/MagbizHook'

const MobileMenu = ({anchor, onClose}) => {

  const performUserOperation = useUserOperation()

  const onLogout = () => {
    performUserOperation({action:"LOGOUT", body:{}})
    onClose()
  }

  return (
    <Menu
      anchorEl={anchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchor)}
      onClose={() => onClose()}
    >
      <MenuItem onClick={onLogout}>
        <IconButton color="inherit">
          <PowerSettingsNewIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  )
}

export default MobileMenu
