import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import classNames from 'classnames';
import MaterialIcon from 'react-material-iconic-font'
import { withStyles } from '@material-ui/core/styles';

import {AppBar,Typography, Toolbar, Grid, List, ListSubheader, ListItem, Divider} from '@material-ui/core';

import {actionPerform as actionPerformInfo} from '../../redux/Info'
import settings from '../../settings'
import {KTSelectField,KTDateField,KTTimeField} from '../../KT'
import {MagbizUI} from '../../utils/Magbiz'

import UISectionList from './UISectionList';

let drawerWidth = 300;

const styles = theme => {
  return {
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    subText:{
      fontSize: '0.7rem',
      backgroundColor:"white"
    },
    menuItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.8rem'
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }
  }
};

class UIViewer extends React.Component {

  state = {
    items: (this.props.module.onCreateItemList ? this.props.module.onCreateItemList(this.props.module.data[this.props.module.searchfield]) : null),
    data: this.props.module.data,
    selected_item: null,
  }

  componentDidMount(){
    this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: {...this.props.module.filter} })
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.app.interface.search_text !== nextProps.app.interface.search_text){
      if(this.props.module.onCreateItemList){
        const items = this.props.module.onCreateItemList(MagbizUI.getDataSearched(this.props.module.data[this.props.module.searchfield], this.props.module.searchable, nextProps.app.interface.search_text))
        this.setState({items: items})
        return false
      }
    }
    if(this.props.module !== nextProps.module){
      if(this.props.module.data !== nextProps.module.data){
        this.setState({
          data: nextProps.module.data,
          items: (this.props.module.onCreateItemList ? this.props.module.onCreateItemList(nextProps.module.data[this.props.module.searchfield]) : null)
        })
        return false
      }
      return true
    }
    if(this.props.app.screen !== nextProps.app.screen){return true}
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.state !== nextState){return true}
    return false
  }

  _onItemSelect = (event, item) => {
    this.setState({selected_item: item})
    if(this.props.module.onItemSelect){
      this.setState({data: this.props.module.onItemSelect(this.props.module.data, item)})
    }
  }

  _onFilterDateTimeChange = (dateObj) => {
    this.onFilterChange(dateObj)
  }

  _onFilterSelectChange = (event) => {
    this.onFilterChange(event.target)
  }

  onFilterChange = (target) => {
    if(this.props.module.filter.hasOwnProperty(target.name)){
      let filter = (this.props.info.filters[this.props.module.name] ? this.props.info.filters[this.props.module.name] : {...this.props.module.filter})
      filter[target.name].value = target.value
      filter[target.name].text = (typeof target.label !== 'undefined' ? target.label : target.value)
      this.props.actionPerformInfo("INFO_APPLY_FILTER", {fdata:this.props.module.name, filter: filter })
      if(this.props.module.onFilterChange){
        this.props.module.onFilterChange(filter, target.name)
      }
    }
  }

  render() {
    //console.log("Render UIViewer : "+this.props.module.name)
    const {classes} = this.props
    const filters = this.props.info.filters
    const tlang = this.props.intl.formatMessage

    const page_name = tlang({"id":"sidebar."+this.props.match.path.replace("/"+settings.name+"/","").replace("/detail","").replace("/","_")})
    const docWidth = (this.props.width ? this.props.width : this.props.app.screen.width)
    const showSideBar = (this.props.module.onCreateItemList && docWidth >= 1280 ? true : false)
    const docContentWith = docWidth - (showSideBar ? drawerWidth : 0)
    const docContentHeight = (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2)

    return (
      <Fragment>
        <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark,zIndex: 900}}>
          <Toolbar >
            <div className={classes.sectionDesktop} style={{flexDirection:'column', justifyContent:'center', marginRight:10}}>
              <Grid container direction="column" justify="center">
                <Typography variant="subtitle1" color="inherit" >
                  {page_name}
                </Typography>
              </Grid>
            </div>
            <div className={classes.sectionDesktop} style={{marginLeft:10, marginRight:10}}>
              <MaterialIcon type={"filter-list"} />
            </div>

            <div className={classes.sectionMobile} style={{flexGrow: 1}} />

            {this.props.module.filter && Object.values(this.props.module.filter).map((item, index) => {
              if(item === null) return null
              if(item.isHide) return null
              switch(item.type) {
                 case "select": {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" " align="center"
                         icon={item.icon} defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         suggestions={item.list} async={Boolean(item.async)}
                         onChange={(e) => this._onFilterSelectChange(e)} small light />
                     </div>
                   )
                 }
                 case "time": {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTTimeField type="time" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" "  align="center"
                         defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         onChange={(e) => this._onFilterDateTimeChange(e)} small light outline notblank />
                     </div>
                   )
                 }
                 case "date" : {
                   return (
                     <div key={index} className={(index <= 1 ? "" : classes.sectionDesktop )} style={{marginLeft:5, marginRight:5, width:item.width+'px'}}>
                       <KTDateField type="date" name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" "  align="center"
                         defaultValue={(filters[this.props.module.name] ? filters[this.props.module.name][item.name].value : item.value)}
                         onChange={(e) => this._onFilterDateTimeChange(e)} small light outline />
                     </div>
                   )
                 }
                 default: {
                   return null
                 }
              }
            })}

            <div className={classes.sectionDesktop} style={{flexGrow: 1}} />

          </Toolbar>
        </AppBar>

        {showSideBar && this.state.items &&
        <div style={{width:drawerWidth-1, height:(this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2),
              maxHeight: (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2), overflow: 'auto',
              float:"left", backgroundColor:"white", outline:"1px solid #f2f2f2"
          }} >
          <List component="nav"
            subheader={this.state.items.title && <ListSubheader component="div" classes={{root: classes.subText}}>
              {this.state.items.title+" ("+this.state.items.list.length+")"}
            </ListSubheader>}>
            {this.state.items.list.map((item, index) => (
              <ListItem button key={index} onClick={(e) => this._onItemSelect(e, item)} style={{backgroundColor:(this.state.selected_item && this.state.selected_item.key === item.key ? "#EBF4F6" : "transparent")}} >
                {this.props.module.onCreateItemRow &&
                  this.props.module.onCreateItemRow(item)}
              </ListItem>
            ))}
          </List>
          <Divider />
        </div>}

        <div className={classNames(classes.content, {
            [classes.contentShift]: showSideBar,
          })}>

          <div style={{
            width:docContentWith,
            height:docContentHeight,
            position:"relative",
            overflow:"auto"
          }}>
            {this.props.module.type === "sectionlist" &&
              <UISectionList width={docContentWith} height={docContentHeight} />}

            {this.props.module.type === "custom" &&
            React.Children.map(this.props.children, child => {
              return React.cloneElement(child, {
                module: this.props.module,
                data: this.state.data,
                width: docContentWith,
                height:docContentHeight
              });
            }) }

            {this.props.module.controlPanel && <div style={{position:"absolute", top:5, left:60}}>
                {this.props.module.controlPanel()}
            </div>}

            {this.props.module.infoPanel && <div style={{position:"absolute", bottom:5, left:100}}>
                {this.props.module.infoPanel()}
            </div>}

          </div>

        </div>

      </Fragment>
    );

  }

}

export default withRouter(connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformInfo
})(injectIntl(withStyles(styles, { withTheme: true })(UIViewer))));
