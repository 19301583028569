import React from "react";
import KTInputField from "./KTInputField";
import NumberFormat from 'react-number-format';

class KTNumberField extends React.Component {

  state = {
    value: this.props.value
  }

  render(){
    return (
      <NumberFormat customInput={KTInputField} thousandSeparator="," {...this.props}
        fixedDecimalScale={this.props.numberType.startsWith("decimal")}
        decimalScale={(this.props.numberType.startsWith("decimal") ? parseInt(this.props.numberType.split(",")[1]) : 0)}
        />
    );
  }

}

export default KTNumberField;
