import React from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from "react-to-print";
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {Grid,InputBase,Typography,IconButton,Tooltip,Toolbar,AppBar} from '@material-ui/core';
import {Menu as MenuIcon,Search as SearchIcon,Print as PrintIcon,
      PowerSettingsNew as PowerSettingsNewIcon,MoreVert as MoreIcon,Fullscreen as FullscreenIcon,FullscreenExit as FullscreenExitIcon} from '@material-ui/icons';
import LanguageIcon from '@material-ui/icons/Language';

import {useApp, useUserOperation} from '../utils/MagbizHook'
import settings from  '../settings'

const containerWidth = 600

const TopNavBar = ({classes, onToggleDrawer, onMobileMenuOpen}) => {

  const performApp = useApp()
  const performUserOperation = useUserOperation()

  const userdata = useSelector(state => state.user.userdata)
  const appscreen = useSelector(state => state.app.screen)
  const appinterface = useSelector(state => state.app.interface)
  const lang = useSelector(state => state.app.lang)

  const onLogout = () => {
    performUserOperation({action:"LOGOUT", body:{}, callback:(success) => {
      if(success){
        window.location = "/"
      }
    }})
  }

  const onFullscreen = () => {
    performApp("APP_SETUP_SCREEN", {...appscreen, isFullscreen:!appscreen.isFullscreen})
  }

  const onChangeLanguage = () => {
    performApp("APP_CHANGE_LANG", (lang === "th" ? "en" : "th" ))
    window.location.reload()
  }

  const onGoToMainPage = () => {
    window.location = "/"
  }

  if(window.location.href.indexOf("/"+settings.name+"/sites/") !== -1){
    return null
  }

  return (
    <AppBar position="sticky">
      <Toolbar >
        <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={() => onToggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        {settings.logo_head_text && appscreen.width >= containerWidth && <div style={{color:"white", fontSize:18, fontWeight:500}}>
          {settings.logo_head_text}
        </div>}
        {!settings.logo_head_text && appscreen.width >= containerWidth &&
          <img className={classes.title} src={(userdata.logo ? userdata.logo : window.location.origin+"/"+settings.logo_head)} alt="Logo" style={{cursor:'pointer',width:'auto',height:25}} onClick={onGoToMainPage} />
        }
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={(e) => {
              performApp("APP_SETUP_INTERFACE", {...appinterface, search_text:e.target.value})
            }}
          />
        </div>
        <div className={classes.grow} />

        <div className={classes.sectionDesktop}>
          {appinterface.printRef &&
          <ReactToPrint
            trigger={() => <Tooltip title="Print"><IconButton color="inherit"><PrintIcon /></IconButton></Tooltip>}
            onBeforePrint={() => {
              if(appinterface.onBeforePrint){
                appinterface.onBeforePrint()
              }
            }}
            onAfterPrint={() => {
              if(appinterface.onAfterPrint){
                appinterface.onAfterPrint()
              }
            }}
            content={() => appinterface.printRef}
          />}
          {userdata &&
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', marginLeft:10, marginRight:10}}>
            <Grid container direction="column" justify="center">
              <Typography variant="caption" color="inherit" style={{fontSize:'0.6rem'}}>
                {userdata.org_name}
              </Typography>
              <Typography variant="caption" color="inherit" >
                {userdata.role_desc ? userdata.username + " ("+userdata.role_desc+")": "User : "+userdata.username+(userdata.branch ? " ("+userdata.branch+")" : "") }
              </Typography>
            </Grid>
          </div>}
          {false &&
          <Tooltip title="Change Language">
            <IconButton color="inherit" onClick={onChangeLanguage}>
              <LanguageIcon />
            </IconButton>
          </Tooltip>}
          <Tooltip title="Fullscreen">
            <IconButton color="inherit" onClick={onFullscreen}>
              {(appscreen.isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon /> )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout"><IconButton color="inherit" onClick={onLogout}><PowerSettingsNewIcon  /></IconButton></Tooltip>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton aria-haspopup="true" onClick={onMobileMenuOpen} color="inherit">
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const styles = theme => {
  return {
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 10,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit * 3,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }
  }
}

export default withStyles(styles, { withTheme: true })(TopNavBar)
