import React,{Fragment} from 'react';
import { connect} from 'react-redux';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import {KTIcon} from  '../../../KT';

import MaterialIcon from 'react-material-iconic-font'
import {MagbizUser} from  '../../../utils/Magbiz';

const styles = theme => {
  return {
    iconRoot:{
      color:theme.palette.secondary.contrastText,
      width:20
    },
    iconRoot2:{
      color:theme.palette.secondary.contrastText,
      width:20,
      marginRight:0
    },
    subTextColor:{
      color:'#F4F4F4',
      fontSize: '0.7rem'
    },
    menuItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.8rem'
    },
    menuSubItem:{
      paddingLeft: theme.spacing.unit * 4,
    },
    menuSubItemPrimaryText:{
      color:theme.palette.secondary.contrastText,
      fontSize: '0.7rem'
    }
  }
};

class Item extends React.Component {

  state = {
    open: false,
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  }

  render(){
    const tlang = this.props.intl.formatMessage;
    const {index, item, onItemSelected, selectedItem, type, classes} = this.props

    if(!MagbizUser.checkAuthorized(item, this.props.user.userdata)) return null

    if (!item.child_routes) {
      return (
        <ListItem button key={index} component={(type === "link" ? NavLink : null)} to={item.path} onClick={() => onItemSelected(item)} style={{backgroundColor:(selectedItem && selectedItem.path === item.path ? "#EBF4F6" : "transparent")}}>
          <ListItemIcon style={{marginRight:0}} classes={{root: classes.iconRoot}}>
            {item.menu_icon ?
             (item.icontype ? <KTIcon vicon={item.menu_icon} vicontype={item.icontype} color="#424242" size={24} />
              : <MaterialIcon type={item.menu_icon} large /> ) : <div></div> }
          </ListItemIcon>
          <ListItemText primary={tlang({id:item.menu_title})} classes={{primary:classes.menuItemPrimaryText}}/>
        </ListItem>
      )
    }else{
      return (
        <Fragment>
          <ListItem button key={index} onClick={this.handleClick}>
            {item.menu_icon && <ListItemIcon style={{marginRight:0}} classes={{root: classes.iconRoot}}>
              {item.icontype ? <KTIcon vicon={item.menu_icon} vicontype={item.icontype} color="#424242" size={24} />
                : <MaterialIcon type={item.menu_icon} large /> }
            </ListItemIcon>}
            <ListItemText primary={tlang({id:item.menu_title})} classes={{primary:classes.menuItemPrimaryText}}/>
            <ListItemIcon style={{marginRight:0}} classes={{root: classes.iconRoot2}}>
              {this.state.open ? <MaterialIcon type="unfold-less" /> : <MaterialIcon type="unfold-more" /> }
            </ListItemIcon>
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.child_routes.map((subItem1, index1) => {

                if(!MagbizUser.checkAuthorized(subItem1, this.props.user.userdata)) return null

                return (
                  <ListItem button key={index1} component={(type === "link" ? NavLink : null)} to={subItem1.path} onClick={() => onItemSelected(subItem1)} className={classes.menuSubItem} style={{backgroundColor:(selectedItem && selectedItem.path === subItem1.path ? "#EBF4F6" : "transparent")}}>
                    {subItem1.menu_icon && <ListItemIcon style={{marginRight:0}} classes={{root: classes.iconRoot}}>
                      {subItem1.icontype ? <KTIcon vicon={subItem1.menu_icon} vicontype={subItem1.icontype} color="#424242" size={24} />
                        : <MaterialIcon type={subItem1.menu_icon} large /> }
                    </ListItemIcon>}
                    <ListItemText primary={tlang({id:subItem1.menu_title})} classes={{primary:classes.menuSubItemPrimaryText}}/>
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        </Fragment>
      )
    }
  }

}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default connect(({user}) => {
	return {user};
}, {

})(withStyles(styles, { withTheme: true })(injectIntl(Item)));
