import React,{Fragment} from 'react';
import { injectIntl } from 'react-intl';
import { connect} from 'react-redux';
import { withRouter } from "react-router";
import classNames from 'classnames';
import MaterialIcon from 'react-material-iconic-font'
import { withStyles } from '@material-ui/core/styles';
import {AppBar,Typography, Toolbar, Tooltip, Grid, IconButton, CircularProgress} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import { NotificationManager } from 'react-notifications';

import {actionPerform as actionPerformInfo} from '../../redux/Info'
import settings from '../../settings'
import {KTButton, KTSelectField} from '../../KT'
import {MagbizGeneral} from '../../utils/Magbiz'

import DocDefaultContent from './DocDefaultContent';
import TableInfLoaderList from './TableInfLoaderList';

const drawerWidth = 240;

const styles = theme => {
  return {
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      backgroundColor:"rgb(211, 47, 47)"
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }
};

class ImportView extends React.Component {

  fetchFieldsValue = (detail) => {
    const fetchGroupValue = (objects, group) => {
      if(group.fields){
        const fields = Object.values(group.fields).reduce((objects1, ofield) => {
         let field = {...ofield}
         if(detail){
           field.value = detail[field.name]
           if(field.onAssign){
             field.onAssign(field)
           }
         }
         objects1[field.name] = field
         return objects1
        }, {})
        return {...objects, ...fields}
      }else{
        return objects
      }
    }
    let fields = Object.values(this.props.module.page_view.group_fields).reduce((objects, group) => {
      return fetchGroupValue(objects, group)
    }, {})
    return fields
  }

  state = {
    fields: this.fetchFieldsValue(),
    name : "",
    filepath: "",
    doc_option:{
      display:{
        name:"display",
        caption : "Display Option",
        type:"select",
        list: [{ label : "All", value: "All"}, { label : "Only Invalid", value: "OnlyInvalid"}],
        value: "All",
        text: "",
        width:130,
      }
    }
  }

  componentDidMount(){
    this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.name})
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props.app.screen !== nextProps.app.screen){return true}
    if(this.props.app.lang !== nextProps.app.lang){return true}
    if(this.props.info[this.props.module.name] !== nextProps.info[this.props.module.name]){
      if(nextProps.info[this.props.module.name]){
        if(nextProps.info[this.props.module.name].length === 0){
          NotificationManager.error("Empty file !!!");
        }
      }
      return true
    }
    if(this.props.info.action[this.props.module.name] !== nextProps.info.action[this.props.module.name]){return true}
    if(this.props.info.action[this.props.module.name+"_operation"] !== nextProps.info.action[this.props.module.name+"_operation"]){return true}
    if(this.props.module !== nextProps.module){return true}
    if(this.state !== nextState){return true}
    return false
  }

  _onFieldChange = (event) => {
    this.setFieldValue(event.target.name, event.target.value)
  }

  _onDateTimeChange = (dateObj) => {
    this.setFieldValue(dateObj.name, dateObj.value)
  }

  _onFieldSelect = (name, selectId) => {
    let fields = this.state.fields
    fields[name].value = selectId
    if(this.state.fields[name].onSelect){
      fields = this.state.fields[name].onSelect(name, selectId).reduce((objects, item) => {
          objects = {...objects, [item.name] : {...objects[item.name], value: item.value} }
          return objects
      }, fields)
    }
    this.setState({fields:fields})
  }

  _onFileUploadImageChange = (name, filepathurl, filepath) => {
    this.setState({name:name, filepath : filepath, fields:{...this.state.fields, [name] : {...this.state.fields[name], value: filepath} }})
    if(filepath){
      this.loadData(name, filepath)
    }
  }

  _onDocOptionChange = (event) => {
    if(this.state.doc_option.hasOwnProperty(event.target.name)){
      const doc_option = {...this.state.doc_option}
      doc_option[event.target.name].value = event.target.value
      doc_option[event.target.name].text = (typeof event.target.label !== 'undefined' ? event.target.label : event.target.value)
      this.setState({doc_option:doc_option})
    }
  }

  _onBackClick = (event) => {
    this.props.history.goBack()
  }

  _onCancelClick = (event) => {
    this.setState({name:"", filepath : "", fields:this.fetchFieldsValue()})
    this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.name})
  }

  _onConfirmClick = (event) => {
    const body = {name : this.state.name, filepath:this.state.filepath, column:this.props.module.column, sheet_name:this.props.module.sheet_name, doc_type:this.props.module.doc_type, request_timeout:this.props.module.request_timeout}
    this.props.actionPerformInfo("INFO_OPERATION",{ action:this.props.module.actionPerform.confirm,
      body:{...body}, fdata:this.props.module.name+"_operation", callback:((success, result_text) => {
        if(success){
          NotificationManager.info("Import Successful")
          if(this.props.module.parent_name){
            this.props.actionPerformInfo("INFO_CLEAR", {fdata:this.props.module.parent_name})
          }
          if(this.props.module.onSuccess){
            this.props.module.onSuccess()
          }
          this.props.history.goBack()
        }
      })})
  }

  loadData = (name, filepath) => {
    const body = {name:name, filepath:filepath, column:this.props.module.column, sheet_name:this.props.module.sheet_name, doc_type:this.props.module.doc_type, request_timeout:this.props.module.request_timeout}
    this.props.actionPerformInfo("INFO_OPERATION",{ action:this.props.module.actionPerform.checking,
      body:{...body}, fdata:this.props.module.name})
  }

  render() {
    //console.log("Render ImportView : "+this.props.module.name)
    const {classes} = this.props
    const {page_view} = this.props.module
    const tlang = this.props.intl.formatMessage

    let sidebar_name = this.props.match.path.replace("/"+settings.name+"/","").split('/').join('_')
    if(sidebar_name.indexOf("/:") !== -1){
      sidebar_name = sidebar_name.substring(0,sidebar_name.indexOf("/:"))
    }
    const page_name = tlang({"id":"sidebar."+sidebar_name})
    const docWidth = (this.props.width ? this.props.width : this.props.app.screen.width)
    const showSideBar = (this.props.module.menu && docWidth >= 1280 ? true : false)
    const docContentWith = docWidth - (showSideBar ? drawerWidth : 0)
    const docContentHeight = (this.props.height ? this.props.height : window.innerHeight - this.props.app.screen.appBarSize.height*2)

    return (
      <Fragment>

        <AppBar position="sticky" style={{backgroundColor:settings.theme.primary.dark, zIndex: 900}} >
          <Toolbar style={{  paddingLeft:10,paddingRight:10}}>

            <Tooltip title="Back" style={{marginRight:10}}>
              <IconButton color="inherit" onClick={this._onBackClick}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>

            <div style={{ flexDirection:'column', justifyContent:'center', marginRight:10}}>
              <Grid container direction="column" justify="center">
                <Typography variant="subtitle1" color="inherit" >
                  {page_name}
                </Typography>
              </Grid>
            </div>
            <div  style={{margin:10}}>
              <MaterialIcon type={"flip"} />
            </div>
            {this.props.module.enableDisplayOption && this.props.info[this.props.module.name] && this.state.doc_option && Object.values(this.state.doc_option).map((item, index) => {
              if(item === null) return null
              if(item.isHide) return null
              switch(item.type) {
                 case "select": {
                   return (
                     <div key={index} style={{marginLeft:10, marginRight:10, width:item.width+'px'}}>
                       <KTSelectField name={item.name} label={tlang({"id":(item.caption ? item.caption : item.name )})} placeholder=" " align="center"
                         icon={item.icon} defaultValue={item.value}
                         suggestions={item.list} async={Boolean(item.async)}
                         onChange={(e) => this._onDocOptionChange(e)} small light />
                     </div>
                   )
                 }
                 default: {
                   return null
                 }
              }
            })}
            <div style={{flexGrow: 1}} />

            {this.props.info[this.props.module.name] &&
            <Fragment>

              <div style={{display:'flex', marginLeft:5, marginRight:5}}>
                <KTButton size="small" onClick={this._onConfirmClick} style={{fontSize:"0.7rem", backgroundColor:"green"}} loading={this.props.info.action[this.props.module.name+"_operation"]}  >
                  {tlang({"id":"button.import"})}
                </KTButton>
              </div>

              <div style={{display:'flex', marginLeft:5, marginRight:5}}>
                <KTButton size="small" onClick={this._onCancelClick} style={{fontSize:"0.7rem", backgroundColor:"grey"}} >
                  {tlang({"id":"button.cancel"})}
                </KTButton>
              </div>

              <div style={{display:'flex', marginLeft:5, marginRight:5}}>
                <Typography variant="subtitle1" color="inherit" style={{fontSize:12}}>
                  {tlang({"id":"number"})} {this.props.info[this.props.module.name].length} {tlang({"id":"entry"})} (สามารถนำเข้าได้ {this.props.info[this.props.module.name].reduce((object, value) => {
                    if(value.INVALID !== "Y") object += 1
                    return object
                  }, 0)} {tlang({"id":"entry"})})
                </Typography>
              </div>
            </Fragment>}

          </Toolbar>
        </AppBar>

        <div className={classNames(classes.content, {[classes.contentShift]: showSideBar,})}>

          <div style={{width:docContentWith, height:docContentHeight, overflow:"auto"}}>
            <div  className={`document-container `+(this.props.module.page_container_style ? this.props.module.page_container_style : "" )} >

              {(this.props.info.action[this.props.module.name]) &&
              <Grid container direction="row" justify="center" alignItems="center" style={{width:'100%',height:'400px'}} >
                <CircularProgress />
              </Grid>}

              {!this.props.info.action[this.props.module.name] &&
              <Fragment>

                {page_view && [page_view].map((page, pageIndex) => {

                  page.content.group_fields = page.content.groups.reduce((objects, group) => {
                    objects[group] = page.group_fields[group]
                    return objects
                  }, {})

                  return (
                    <div key={pageIndex} className={`document `+(page_view.page)} style={page.style} >
                      <div className={`document-page `+(page_view.page)} style={page.page_style} >

                        {!this.props.info[this.props.module.name]  &&
                        <Grid container >
                          {page.content.group_fields && Object.values(page.content.group_fields).map((groupObj, groupIndex) => {

                            return (
                              <Fragment key={groupIndex} >

                                {(groupObj.fieldType === "default" || MagbizGeneral.value(groupObj.fieldType) === "") &&
                                  <DocDefaultContent groupObj={groupObj} width={this.props.width} mode={"edit"} fields={this.state.fields}
                                  onFieldChange={this._onFieldChange}
                                  onDateTimeChange={this._onDateTimeChange}
                                  onFieldSelect={this._onFieldSelect}
                                  onFileUploadImageChange={this._onFileUploadImageChange}
                                  />}

                              </Fragment>
                            )
                          })}
                        </Grid>}

                        {this.props.info[this.props.module.name]  &&
                        <Grid container >

                          <TableInfLoaderList
                            runnumber={true}
                            column={this.props.module.column}
                            rows={(this.state.doc_option.display.value === "OnlyInvalid" ? this.props.info[this.props.module.name].filter((item) => {
                              return (item.INVALID === "Y" ? true : false)
                            })  : this.props.info[this.props.module.name] )}
                            width={docContentWith}
                            height={docContentHeight} />

                        </Grid>}

                      </div>
                    </div>
                  )
                })}

              </Fragment>}

            </div>
          </div>

        </div>

      </Fragment>
    );

  }

}

export default withRouter(connect(({app, info}) => {
	return {app, info};
}, {
  actionPerformInfo
})(injectIntl(withStyles(styles, { withTheme: true })(ImportView))));
