import React from 'react';
import {Grid} from '@material-ui/core';

import DocDefaultContent from './DocDefaultContent'
const containerWidth = 600

class DocDocumentHeader extends React.Component {

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      return true
    }
    return false
  }

  _onFieldChange = (event) => {
    this.props.onFieldChange(event)
  }

  _onDateTimeChange = (dateObj) => {
    this.props.onDateTimeChange(dateObj)
  }

  _onFieldSelect = (name, selectId) => {
    this.props.onFieldSelect(name, selectId)
  }

  render(){
    //console.log("Render DocDocumentHeader")
    const {header, fields, mode, width} = this.props

    if(!Boolean(header)) return null

    for(let k in header.group_fields){
      header.group_fields[k].fieldStyle = "document"
      header.group_fields[k].noBordor = true
      header.group_fields[k].compact = true
      //if(k === "logo"){
        header.group_fields[k].noPadding = true
      //}
    }

    let titleStyle = {display: "inline-block", minWidth:"60%", borderRadius:2, boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)"}
    if(header.dotDocument){
      titleStyle.borderRadius = 0
      titleStyle.boxShadow = "none"
      titleStyle.border = "1px solid #000"
    }

    const createMarkup = (text) => {
      return {__html: text};
    }

    return (
      <div className={`document-header`} style={{marginTop:10, marginBottom:30}} >

        <Grid container >
          {header.group_fields.logo &&
          <Grid item xs={(width >= containerWidth ? 2 : 4)} >
            <DocDefaultContent contentType="header" groupObj={header.group_fields.logo} width={width} mode={mode} fields={fields}
              onFieldChange={this._onFieldChange}
              onDateTimeChange={this._onDateTimeChange}
              onFieldSelect={this._onFieldSelect} />
          </Grid>}
          <Grid item xs={(width >= containerWidth ? 6+(header.group_fields.logo ? 0 : 2) : 3+(header.group_fields.logo ? 0 : 4) )}>
            {width >= containerWidth &&
            <div style={{marginLeft:(header.group_fields.logo ? 5 : 0)}}>
              <DocDefaultContent contentType="header" groupObj={header.group_fields.top_title} width={width} mode={mode} fields={fields}
                onFieldChange={this._onFieldChange}
                onDateTimeChange={this._onDateTimeChange}
                onFieldSelect={this._onFieldSelect} />
            </div>}
          </Grid>
          <Grid item xs={(width >= containerWidth ? 4 : 5)} style={{textAlign:"right"}}>
            <div style={titleStyle}>
              <div dangerouslySetInnerHTML={createMarkup(header.title+(header.title2 && header.title2 !== " " ? "<br/>"+header.title2 : ""))}
                style={{ textAlign:"center", padding:"10px 12px", fontWeight:600, fontSize:(header.title2 && header.title2 !== " " ? '0.9rem' : '1.0rem'), lineHeight:(header.title2 && header.title2 !== " " ? "17px" : "34px"), height:54}} />
              {header.subtitle && <div dangerouslySetInnerHTML={createMarkup(header.subtitle)}
                style={{textAlign:"center", padding:"5px 12px", fontWeight:400, fontSize:'0.7rem', lineHeight:'0.8rem', minHeight: '0.8rem', backgroundColor:"#f2f2f2"}} />}
            </div>
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default DocDocumentHeader
